import ExerciseTypes from "./ExerciseTypes";

export const mapExerciseToValue = (exerciseId: number) => {
  if (exerciseId === 1) {
    return ExerciseTypes.slideShow;
  } else if (exerciseId === 2) {
    return ExerciseTypes.quiz;
  } else if (exerciseId === 3) {
    return ExerciseTypes.survey;
  } else if (exerciseId === 4) {
    return ExerciseTypes.buzzer;
  } else if (exerciseId === 5) {
    return ExerciseTypes.list;
  } else if (exerciseId === 6) {
    return ExerciseTypes.game;
  }

  return undefined;
};
