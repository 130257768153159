import { useRecoilState, useRecoilValue } from "recoil";
import userLiveStore from "../../store/userLiveStore";
import eventState from "../../store/eventState";
import { useEffect } from "react";
import pusherInstance from "../../lib/clients/pusherInstance";
import userStore from "../../store/userStore";
import { useNavigate } from "react-router-dom";
import { getAllUsers } from "../../lib/services/users";

type UserInfo = {
	firstname: string;
	lastname: string;
	user_id: string | number;
	user_type: "admin" | "user";
	img: string | null
};

interface IMembers {
	[key: number]: UserInfo;
}

type SubscriptionSucceeded = {
	count: number;
	me: {
		id: string;
		info: UserInfo;
	};
	members: IMembers;
};

type EventStatus = {
	event: {
		admin_id: number;
		created_at: string;
		date: string;
		id: number;
		is_live: 1 | 0;
		status: number;
		title: string;
		updated_at: string;
	};
};

type MsgDeleteType = {
	data: {
		deleted_user_id: number;
	};
	eventType: "delete-user";
	channel: null;
};

const usePresenceChannel = () => {
	const [userLive, setUserLive] = useRecoilState(userLiveStore);
	const [, setState] = useRecoilState(eventState);
	const userData = useRecoilValue(userStore);
	const navigate = useNavigate();

	// const [count, setCount] = useState(1);

	const channel_name = "presence-users-online";

	const allUsers = async () => {
		getAllUsers().then(data => {
			setUserLive((prev) => ({
				...prev,
				users: data.data.data.map((item: any) => ({
					firstname: item.first_name,
					lastname: item.last_name,
					user_id: item.id,
					user_type: "user",
					img: item.image
				}))
			}))
		}).catch(() => {});
	}

	useEffect(() => {
		const pusher = pusherInstance(userData.user_id, userData.imgUrl);
		const subscribedChannel = pusher.subscribe(channel_name);

		// console.log("this must appear once presence channel", count);
		// setCount((prev) => prev + 1);
		
		subscribedChannel.bind(
			"pusher:member_removed",
			async (data: { id: string; info: UserInfo }) => {
				const users = userLive.users.filter(
					(u) => u.user_id.toString() !== data.id.toString()
				);
				await allUsers();
				setUserLive((prev) => ({
					count: prev.count - 1,
					users,
				}));
			}
		);

		subscribedChannel.bind(
			"pusher:member_added",
			async (data: { id: string; info: UserInfo }) => {
				await allUsers();
				setUserLive((prev) => ({
					count: prev.count + 1,
					users: [...prev.users, data.info],
				}));
			}
		);

		subscribedChannel.bind(
			"pusher:subscription_succeeded",
			async (data: SubscriptionSucceeded) => {
				await allUsers();
				const users: UserInfo[] = [];
				for (const key in data.members) {
					users.push(data.members[key]);
				}

				setUserLive((prev) => ({
					count: data.count - 1,
					users: [...prev.users, ...users],
				}));
			}
		);

		subscribedChannel.bind("event-status", (data: EventStatus) => {
			setState((prev) => ({
				...prev,
				live: true,
				eventTitle: data.event.title,
			}));
		});

		subscribedChannel.bind("delete-user", (data: MsgDeleteType) => {
			if (data.data.deleted_user_id.toString() === userData.user_id) {
				localStorage.clear();
				navigate("/un-authorized-access");
			}
		});

		return () => {
			pusher.unsubscribe(channel_name);
			pusher.unbind_all();
			pusher.disconnect();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};

export default usePresenceChannel;
