import { Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Logo from "../../icons/Logo";
import { useRecoilState } from "recoil";
import userStore, { defaultUserStore } from "../../store/userStore";
import { useQuery } from "@tanstack/react-query";
import { checkUserExists } from "../../lib/services/users";
import { useEffect } from "react";
// import pako from "pako";

const FirstAccess = () => {
	const { t } = useTranslation();

	const [{ user_id }, setState] = useRecoilState(userStore);

	const { isLoading, isError } = useQuery({
		queryKey: ["user-exists"],
		queryFn: () => checkUserExists(user_id),
		retry: false,
	});

	useEffect(() => {
		if (isError) {
			setState(defaultUserStore);
		}

		// const gezipedData = atob(
		// 	"eNqlVUtvE0kQ/iulOUeWTWBZckFsAGkPwO5G4gIoqkyXnZL6MenHCIL473zdE89Em3DAlmV5XN1dXfM9qr53hjN3Z987Nd3Z6UmXNVvpzrqLEkf5RpvupDOS+qhD1uCx8CYTl0yec0lk5EqzJpKUaRRLY7BlyJwoaV7RR4maeVpPQsF5PNbDNyWQYMMrJ7mdrV+O/bVm6XNAKq/s9tmyOOTzmUywIQph0a1QV/42yFQzHi49u6VurKrjHQK+WHvSRfYmuP2/MEpkay+HGHYRpeHYerVe49BNQSV4T4Q+TZBskF6+Zmw5D05oZFuyUh/cYHFSR9zqs5BlyoUpBWM0bfkWOQS7PCkliaPe4pc90Ig4fC10zUpRexlLDi8fw7i9a3Hkgq0lsZD6BZAV/Vs46cSE4mGCxt1hDPRJvq7oTZqwDtut9soNuRowoM81UlZ0UfeGREAGMVwCYmZwN/8DV/0O6gCCw69QurhDIOcw77scJPaAqRGyh3q/NHOw/nEyJXsyJ3svJceD85zOef726Yiyvjws7BzM3hS2QtsoEI2/Bf9K6gbZea0o4iYdNX8u67WsaQuOeiZcSI6hFy9x0UyVlDwUwetGaYICzET0lvvpWDHIoRAHotVlFrXgUmtryOigqa+yM6gDTsrI8vuMPp3f9T/ZBcux6fxQKp7N2T70PYQbPNujEv6xlMfxzoOB3rEezu4iF3gLqFVCExJUOrchOkYowSVCTlOpfYjwPEQ0uOpokDREAd6T943AT7/k9kMzJJxaieuLg4QoCkLiTW2XWtoSuiUcq9XtqKLeGWVAYuzCBgHdk+/TiqDIBCWiK8d9n+S+L4kddhZv0D9Kqm+AfFbrfYf4/PmM0V/iF1wOZfHPOd27YFHc0QlfLK0jHFPfLIqnj7Z/C2hr978ClHuLNz9jLsCt+5CHM6EJH9D2QccwFNzsmqTqaAgoBsXFZTQ8MglKbdi1dXN1u7R/Bj+odsQDpiIuDPcnw6tG+9QgrrnOEB+uqqjQK86LHXiZ0sg3hAiZStXhNAGmmNRetUW7aXrs7x/Lxa3on3urbYBzldRUaR0rvz9B1jPSb7lXW+3V0DxYC5vNPXWZCvVdnzgy7TIFXmuzcas1HZj1Cz4/fgIF409Y"
		// );
		// const gzipedDataArray = Uint8Array.from(gezipedData, (c) =>
		// 	c.charCodeAt(0)
		// );

		// console.log("gzipeddata", gzipedDataArray);
		// const ungzipedData = pako.ungzip(gzipedDataArray);

		// const x = new TextDecoder().decode(ungzipedData);
		// console.log(JSON.parse(x));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isError]);

	return (
		<div
			className="h-dvh flex flex-col items-center justify-center gap-4 md:gap-[50px] text-center px-4 !object-cover !object-center"
			style={{
				backgroundImage: "url('https://bayer-be.axensodev.com/images/bg.png')",
				backgroundAttachment: "fixed",
				backgroundPosition: "center",
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
			}}
		>
			<div className="text-white rounded-[20px] bg-theme-blue p-8 flex items-center flex-col gap-4 md:gap-[10px] w-full md:max-w-[760px]">
				<p className="text-2xl leading-7 md:text-[28px] md:leading-[34px]">
					{t("Welcome")}!
				</p>
				<h2 className="text-[30px] leading-9 md:text-[42px] md:leading-[50px] font-bold">
					{t("Emerging technologies in healthcare")}
				</h2>
				<p className="text-base leading-5 text-white tracking-[0.01px]">
					{t(
						"At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores"
					)}
				</p>
				<Button
					as={Link}
					size={"md"}
					to="/login"
					variant={"primary"}
					className="primary-btn orange-btn w-full md:w-[400px] px-16 !py-[13px] md:!py-[18px] md:!p-5 leading-[21.6px] font-bold text-lg"
					isLoading={isLoading}
					isDisabled={isLoading}
				>
					{t("Join")}
				</Button>
			</div>
			<Logo />
		</div>
	);
};

export default FirstAccess;
