// import { useRecoilState } from "recoil";
// import eventState from "../../store/eventState";
// import { Link } from "react-router-dom";
// import Repeat from "../../icons/Repeat";
import { useFullscreen } from "../../hooks/useFullScreenMantine";
import { MdFullscreen, MdFullscreenExit } from "react-icons/md";
import Chip from "../../shared/Chip";

const ActivityControl = () => {
	// const [, setState] = useRecoilState(eventState);
	const { fullscreen, toggle } = useFullscreen();

	return (
		<Chip className="flex items-center justify-center self-stretch gap-2">
			<button
				className={`p-[14px] rounded transition-all duration-300 ${
					fullscreen ? "violet-btn !text-white" : "bg-white !text-violet-600"
				} disabled:bg-gray-50 disabled:!text-gray-700 hidden md:block`}
				// disabled={!state.live}
				onClick={toggle}
			>
				{fullscreen ? (
					<MdFullscreenExit size={22} />
				) : (
					<MdFullscreen size={22} />
				)}
			</button>
			{/* <Link
				to="."
				onClick={() => {
					setState((prev) => ({
						...prev,
						showHeaderControl: false,
						activityTitle: "",
						activity: undefined
					}))
				}}
				className={`rounded transition-all duration-300 aspect-square md:w-12 w-9 md:h-12 h-9 bg-orange-600 stroke-white flex items-center justify-center`}
			>
				<Repeat className="md:hidden" width={19} height={20} stroke="white" fill="white" />
				<Repeat className="hidden md:block" width={21} height={23} stroke="white" fill="white" />
			</Link> */}
		</Chip>

	);
};

export default ActivityControl;
