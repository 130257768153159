import axios from "../clients/axiosInstance";
import END_POINT from "../api/END_POINTS";

const { V1 } = END_POINT;

export const joinEvent = (
	firstName: string,
	lastName: string,
	mobileNumber: string
) => {
	return axios.post(V1.JOIN_EVENT, {
		last_name: lastName,
		first_name: firstName,
		phone: mobileNumber,
		profession: 1,
		event: 1,
	});
};

export const getAvatars = () => {
	return axios.get(V1.GET_AVATARS);
};

export const setAvatar = (avatar: string, user_id: string) => {
	return axios.post(V1.SET_AVATAR, { user_id, avatar });
};

export const getAllUsers = () => {
	return axios.get(V1.GET_ALL_USERS);
};

export const checkOnline = () => {
	return axios.get(V1.IS_ONLINE);
};

export const getProfessions = () => {
	return axios.get(V1.GET_PROFESSIONS);
};

export const isLastActivity = () => {
	return axios.get(V1.IS_LAST_CONTENT)
}

export const checkUserExists = (id: string | number) => {
	return axios.get(V1.DO_USER_EXISTS(id));
}