import { checkboxAnatomy as parts } from "@chakra-ui/anatomy";
import {
	createMultiStyleConfigHelpers,
	defineStyle,
} from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } =
	createMultiStyleConfigHelpers(parts.keys);

// default base style from the Checkbox theme
const baseStyle = definePartsStyle({
	control: {
		width: "32px",
		height: "32px",
		borderRadius: "2px",
	},
	icon: {
		color: "white",
	},
});

// Defining a custom variant
const custom = definePartsStyle({
	control: defineStyle({
		_checked: {
			backgroundColor: "#9747FF",
		},
		borderWidth: "1px",
		borderColor: "#D5B5FF",
	}),
	container: {
		display: "flex",
		flexDir: "row-reverse",
		w: "100%"
	},
	label: {
		color: "#534A5E",
		textAlign: "left",
		flex: "1",
		fontSize: "20px",
		leading: "24px",
	}
});

const profession = definePartsStyle({
	control: defineStyle({
		_checked: {
			backgroundColor: "#9747FF",
		},
		borderWidth: "1px",
		borderColor: "#D5B5FF",
		backgroundColor: "#D5B5FF"
	}),
	container: {
		px: "16px",
		py: "13px",
		rounded: "2px",
		backgroundColor: "white",
		w: "full"
	},
	label: {
		fontSize: "18px",
		lineHeight: "21.6px",
		fontFamily: "Fira Sans"
	}
});

const variants = {
	custom: custom,
	profession,
};

export const checkboxTheme = defineMultiStyleConfig({
	baseStyle,
	variants,
});
