import { atom } from "recoil";
import { QuizTypes } from "../hooks/reFetcher/types/quiz.types";
import { SurveyTypes } from "../hooks/reFetcher/types/survey.api.types";
import { ReturnHome } from "../hooks/reFetcher/types/refetcher.api.types";

const tempData = atom<ReturnHome | SurveyTypes | QuizTypes | undefined | null>({
	key: "tempDataKey",
	default: undefined,
});

export default tempData;
