import { useEffect } from "react";
import pusherInstance from "../../lib/clients/pusherInstance";
import { useRecoilState, useRecoilValue } from "recoil";
import eventState from "../../store/eventState";
import { useNavigate } from "react-router-dom";
import userStore from "../../store/userStore";
import useSlideshowChannel from "./useSlideshowChannel";
import useListChannel from "./useListChannel";
import useQuizChannel from "./useQuizChannel";
import useSurveyChannel from "./useSurveyChannel";
import useBuzzerChannel from "./useBuzzerChannel";
import useGameChannel from "./useGameChannel";
import allowPusher from "../../store/allowPusher.store";

type MsgType = {
	data: {
		content_id: number;
		content_type: number;
		content_type_name: string;
		content_title: string;
		content_description: string;
	};
	eventType: string;
};

const useDataStandby = () => {
	const navigate = useNavigate();

	const userKey = useRecoilValue(userStore);
	const pusherStart = useRecoilValue(allowPusher);
	const [, setState] = useRecoilState(eventState);
	// const [count, setCount] = useState(1);

	const { handleInitSlideshow } = useSlideshowChannel();
	const { handleInitList } = useListChannel();
	const { handleQuizInit } = useQuizChannel();
	const { handleInitSurvey } = useSurveyChannel();
	const { handleInitBuzzer } = useBuzzerChannel();
	const { handleInitGame } = useGameChannel();

	const channel_name = "private-presenter";

	const handleRouteNavigation = (data: MsgType) => {
		switch (data.data.content_type) {
			case 1:
				navigate(`/event/activity/${data.data.content_type}/slideshow`, {
					replace: true,
				});
				break;
			case 2:
				navigate(`/event/activity/${data.data.content_type}/quiz`, {
					replace: true,
				});
				break;
			case 3:
				navigate(`/event/activity/${data.data.content_type}/survey`, {
					replace: true,
				});
				break;
			case 4:
				navigate(`/event/activity/${data.data.content_type}/buzzer`, {
					replace: true,
				});
				break;
			case 5:
				navigate(`/event/activity/${data.data.content_type}/list`, {
					replace: true,
				});
				break;
			case 6:
				navigate(`/event/activity/${data.data.content_type}/game`, {
					replace: true,
				});
				break;
			default:
				console.log("triggered here")
				setState((prev) => ({
					...prev,
					showHeaderControl: false,
					activity: undefined
				}));
				break;
		}
	};

	useEffect(() => {
		const pusher = pusherInstance(userKey.user_id, userKey.imgUrl);

		const standByChannel = pusher.subscribe(channel_name);

		if (pusherStart) {
			standByChannel.bind("content-standby", (data: MsgType) => {
				setState((prev) => ({
					...prev,
					activeContentId: data.data.content_id,
					activity: {
						activityName: data.data.content_type_name,
						activityType: data.data.content_type,
					},
					activityTitle: data.data.content_title,
					description: data.data.content_description,
				}));

				handleRouteNavigation(data);
			});

			// standByChannel.bind(
			// 	"content-end",
			// 	(_: { data: []; eventType: "content-end" }) => {
			// 		setState((prev) => ({
			// 			...prev,
			// 			activeContentId: -1,
			// 			activity: undefined,
			// 			showHeaderControl: false,
			// 			activityTitle: "",
			// 		}));
			// 		navigate("/event/activity");
			// 	}
			// );

			handleInitSlideshow(pusher);
			handleInitList(pusher);
			handleQuizInit(pusher);
			handleInitSurvey(pusher);
			handleInitBuzzer(pusher);
			handleInitGame(pusher);
		}

		return () => {
			pusher.unsubscribe(channel_name);
			pusher.unbind_all();
			pusher.disconnect();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pusherStart]);
};

export default useDataStandby;
