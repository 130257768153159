interface Props {
	children: React.ReactNode;
	className?: string;
	onClick?: () => void;
}

const Chip = ({ children, className, onClick }: Props) => {
	return (
		<div
			className={`rounded bg-dark-semi p-1 md:p-2 ${className}`}
			onClick={onClick}
		>
			{children}
		</div>
	);
};

export default Chip;
