import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import surveyState from "./store";

const SurveyErrorCatcher = () => {
	const navigate = useNavigate();

	const survey = useRecoilValue(surveyState);

	useEffect(() => {
		if (
			(survey.total === 0 || survey.questions.length === 0) &&
			window.location.href !== "/event/activity/lobby"
		) {
			navigate("/event/activity/lobby");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <Outlet />;
};

export default SurveyErrorCatcher;
