/* eslint-disable react-hooks/exhaustive-deps */
// import { useState } from "react";
import { useRecoilState } from "recoil";
import eventState from "../../store/eventState";
import { useNavigate } from "react-router-dom";
import surveyState from "../../pages/activities/survey/store";
import userSubmission from "../../pages/activities/survey/activity/store";
import Pusher from "pusher-js/types/src/core/pusher";

export type Actions =
	| "start"
	| "show"
	| "end"
	| "show_results"
	| "prev"
	| "next";

type MsgType = {
	data: {
		content_id: number;
		content_type: number;
		content_type_name: "survey";
		action: Actions;
		showable_content: {
			description: string;
			id: number;
			overall_progress: string;
			title: string;
			image: null | string;
			type: 3;
			type_name: "Survey";
			questions: {
				id: number;
				text: string;
				description: string;
				type_name: "Single" | "Multiple";
				options: {
					id: number;
					text: string;
					option_progress: number;
					option_percentage: number;
				}[];
			}[];
		};
		question_id: null;
		results: [];
	};
	eventType: string;
};

const useSurveyChannel = () => {
	const navigate = useNavigate();

	const [, setSurvey] = useRecoilState(surveyState);
	const [, setState] = useRecoilState(eventState);
	const [, setSubmissionData] = useRecoilState(userSubmission);
	// const [count, setCount] = useState(1);
	const channel_name = "private-presenter";

	const handleStartEvent = (data: MsgType) => {
		setState((prev) => ({
			...prev,
			activity: {
				activityName: data.data.content_type_name,
				activityType: data.data.content_type,
			},
			activeContentId: data.data.content_id,
			activityName: data.data.content_type_name,
			activityTitle: data.data.showable_content.title,
			showHeaderControl: true,
		}));
		const questions = data.data.showable_content.questions.map((item) => ({
			description: item.description,
			id: item.id.toString(),
			question: item.text,
			type_name: item.type_name,
			options: item.options.map((op) => ({
				id: op.id.toString(),
				option: op.text,
				option_percentage: 0,
				option_progress: 0,
			})),
		}));
		setSurvey({
			currentIndex: 0,
			total: questions.length,
			questions: questions,
		});
		setSubmissionData({
			currentIndex: 0,
			total: questions.length,
			userSelected: questions.map((item) => ({
				id: item.id,
				selected: [],
			})),
		});
	};

	// const handleCatchUp = (data: ListAnswer) => {
	// 	setState((prev) => ({
	// 		...prev,
	// 		activity: {
	// 			activityName: data.data.type_name,
	// 			activityType: data.data.type,
	// 		},
	// 		activeContentId: data.data.id,
	// 		activityName: data.data.type_name,
	// 		activityTitle: data.data.title,
	// 		description: data.data.description,
	// 		showHeaderControl: true,
	// 	}));
	// 	const questions = data.data.questions.map((item) => ({
	// 		description: item.description,
	// 		id: item.id.toString(),
	// 		type_name: item.type_name,
	// 		question: item.text,
	// 		options: item.options.map((op) => ({
	// 			id: op.id.toString(),
	// 			option: op.text,
	// 			option_percentage: 0,
	// 			option_progress: 0,
	// 			option_prof: {
	// 				Dermatologists: op.option_prof.Dermatologists,
	// 				Reumatologists: op.option_prof.Reumatologists,
	// 			},
	// 		})),
	// 	}));
	// 	setSurvey({
	// 		currentIndex: 0,
	// 		total: questions.length,
	// 		questions: questions,
	// 	});
	// 	setSubmissionData({
	// 		currentIndex: 0,
	// 		total: questions.length,
	// 		userSelected: questions.map((item) => ({
	// 			id: item.id,
	// 			selected: [],
	// 		})),
	// 	});
	// };

	const handleInitSurvey = (pusher: Pusher) => {
		const surveyChannel = pusher.subscribe(channel_name);
		// console.log("this must appear once survey channel", count);
		// setCount((prev) => prev + 1);

		surveyChannel.bind("survey", (data: MsgType) => {
			if ("action" in data.data) {
				switch (data.data.action) {
					case "start":
						handleStartEvent(data as MsgType);
						navigate(`/event/activity/${data.data.content_type}/survey/player`);
						break;
					case "end":
						const questions = data.data.showable_content.questions.map(
							(item) => ({
								description: item.description,
								id: item.id.toString(),
								question: item.text,
								type_name: item.type_name,
								options: item.options.map((op) => ({
									id: op.id.toString(),
									option: op.text,
									option_percentage: Math.trunc(op.option_percentage),
									option_progress: Math.trunc(+op.option_progress),
								})),
							})
						);
						setSurvey({
							currentIndex: 0,
							total: questions.length,
							questions: questions,
						});
						navigate(
							`/event/activity/${data.data.content_type}/survey/show-result`
						);
						break;
					case "show_results":
						navigate(
							`/event/activity/${data.data.content_type}/survey/results`
						);
						break;
					// case "next":
					// 	setSurvey((prev) => ({
					// 		...prev,
					// 		currentIndex: prev.currentIndex + 1,
					// 	}));
					// 	break;
					// case "prev":
					// 	setSurvey((prev) => ({
					// 		...prev,
					// 		currentIndex: prev.currentIndex - 1,
					// 	}));
					// 	break;
					default:
						break;
				}
			}
		});

		// surveyChannel.bind(
		// 	"content-end",
		// 	(_: { data: []; eventType: "content-end" }) => {
		// 		setState((prev) => ({
		// 			...prev,
		// 			activeContentId: -1,
		// 			activity: undefined,
		// 			showHeaderControl: false,
		// 			activityTitle: "",
		// 		}));
		// 		navigate("/event/activity");
		// 	}
		// );
	};

	return {
		handleInitSurvey,
	};
};

export default useSurveyChannel;
