import Circle from "../../shared/Circle";
import Chip from "../../shared/Chip";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import userLiveStore from "../../store/userLiveStore";
import Counter from "../../components/animatedCounter/Counter";
import Bars from "../../icons/Bars";

const ParticipantViewer = () => {
	const [users] = useRecoilState(userLiveStore);

	const { t } = useTranslation();

	return (
		<Chip className="text-dark-light stroke-dark-light flex w-fit md:!w-[160px] items-center gap-[6px] self-stretch text-lg font-bold ">
			<Circle className="hidden md:flex w-9 h-9 md:w-[50px] md:h-[50px] items-center justify-center self-stretch bg-white">
				<Bars />
			</Circle>
			<div className="md:hidden">
				<Bars />
			</div>
			<Counter value={users.count} />
			<div className="hidden md:flex min-w-fit flex-col items-start text-[12px] font-normal leading-[11px]">
				<div className="flex-1">{t("USER")}</div>
				<div className="flex-1">{t("ONLINE")}</div>
			</div>
		</Chip>
	);
};

export default ParticipantViewer;
