import Chip from "../../../../shared/Chip";
import { useTranslation } from "react-i18next";

export type QuestionType = {
	description: string;
	total: number;
	activeTrue: boolean | undefined;
	activeFalse: boolean | undefined;
	text: string;
	current: number;
	handleClickTrue: () => void;
	handleClickFalse: () => void;
};

const Question = ({
	activeFalse,
	activeTrue,
	description,
	total,
	handleClickTrue,
	handleClickFalse,
	current,
	text,
}: QuestionType) => {
	const { t } = useTranslation();

	return (
		<Chip className="!bg-transparent p-5 flex flex-col gap-10 w-full justify-between md:justify-normal h-[calc(100dvh-60px-8px-54px-20px)] md:h-[calc(100dvh-106px-20px-54px-20px)] ">
			<div className="flex flex-col gap-5 md:items-center">
				<Chip className="px-4 py-[6px] text-sm md:text-lg text-white bg-violet-400 md:!bg-dark-mid w-fit">
					{current}/{total}
				</Chip>
				<div className="md:text-center">
					<h3 className="text-xl leading-6 md:text-[30px] font-bold md:leading-[36px] text-dolphin-700 prevent-select">
						{text}
					</h3>
					<p
						className="leading-5 md:leading-7 text-dark-mid text-base md:text-2xl mt-[10px] prevent-select"
						dangerouslySetInnerHTML={{ __html: description }}
					/>
				</div>
			</div>
			<div className="flex items-center gap-2 md:gap-[30px] justify-between md:justify-center">
				<button
					onClick={handleClickTrue}
					className={`px-4 transition-all duration-300 bg-white text-green-600 border-2 rounded border-solid border-green-600 font-bold leading-[21.6px] md:leading-7 flex-1 md:flex-none md:px-[60px] py-[11px] !text-lg md:!text-2xl hover:bg-green-600 hover:text-white ${
						activeTrue && "!bg-green-600 !text-white"
					}`}
				>
					{t("True")}
				</button>
				<button
					onClick={handleClickFalse}
					className={`px-4 transition-all duration-300 bg-white text-orange-600 border-2 rounded border-solid border-orange-600 font-bold leading-[21.6px] md:leading-7 flex-1 md:flex-none md:px-[60px] py-[11px] !text-lg md:!text-2xl hover:bg-orange-600 hover:text-white ${
						activeFalse && "!bg-orange-600 !text-white"
					}`}
				>
					{t("False")}
				</button>
			</div>
		</Chip>
	);
};

export default Question;
