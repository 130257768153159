import { atom } from "recoil";

export type QuizAnswerTypes = {
	currentIndex: number;
	total: number;
	answers: {
		id: string;
		userSelect: 1 | -1 | 0;
	}[];
	data: {
		id: string;
		description: string;
		text: string;
		results: {
			true: number;
			false: number;
			true_count: number;
			false_count: number;
			correct_answer: boolean;
		};
	}[];
};

const defaultAnswers: QuizAnswerTypes = {
	currentIndex: 0,
	total: 0,
	answers: [],
	data: [],
};

const userAnswers = atom<QuizAnswerTypes>({
	key: "usersQuizAnswer",
	default: defaultAnswers,
});

export default userAnswers;
