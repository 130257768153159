import { useRecoilState, useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { Button, useToast } from "@chakra-ui/react";
import surveyState from "../store";
import userSubmission from "./store";
import { useTranslation } from "react-i18next";
import { submitUserAnswer } from "../../../../lib/services/survey";
import userStore from "../../../../store/userStore";
import { useState } from "react";
import eventState from "../../../../store/eventState";

const SurveyControl = () => {
	const toast = useToast();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const eState = useRecoilValue(eventState);
	const [surveyData, setSurveyData] = useRecoilState(surveyState);
	const [userSub, setUserSub] = useRecoilState(userSubmission);

	const userData = useRecoilValue(userStore);

	const [loading, setLoading] = useState(false);

	const handleNextSelect = () => {
		setLoading(true);
		submitUserAnswer(
			eState.activeContentId,
			+userData.user_id,
			+userSub.userSelected[userSub.currentIndex].id,
			userSub.userSelected[surveyData.currentIndex]?.selected
		)
			.then(() => {
				if (surveyData.currentIndex < surveyData.total - 1) {
					setSurveyData((prev) => ({
						...prev,
						currentIndex: prev.currentIndex + 1,
					}));
					setUserSub((prev) => ({
						...prev,
						currentIndex: prev.currentIndex + 1,
					}));
				} else {
					navigate("../show-result", {
						relative: "path",
					});
				}
			})
			.catch(() => {
				toast({
					title: t("Something went wrong") + ".",
					description: t("Please try again") + ".",
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			})
			.finally(() => setLoading(false));
	};

	return (
		<div className="flex items-center justify-between md:justify-end gap-5 w-full md:w-auto">
			<Button
				className="orange-btn base-btn !py-[13px] text-lg leading-[21.6px] font-bold flex-1"
				size="md"
				isDisabled={surveyData.currentIndex === 0 || loading}
				onClick={() => {
					if (surveyData.currentIndex !== 0) {
						setSurveyData((prev) => ({
							...prev,
							currentIndex: prev.currentIndex - 1,
						}));
						setUserSub((prev) => ({
							...prev,
							currentIndex: prev.currentIndex - 1,
						}));
					}
				}}
			>
				{t("Prev")}
			</Button>
			<Button
				className={`violet-btn base-btn !py-[13px] text-lg leading-[21.6px] font-bold  flex-1`}
				size="md"
				isDisabled={
					userSub.userSelected[surveyData.currentIndex]?.selected.length ===
						0 || loading
				}
				onClick={handleNextSelect}
			>
				{surveyData.currentIndex === surveyData.total - 1
					? t("Send")
					: t("Next")}
			</Button>
		</div>
	);
};

export default SurveyControl;
