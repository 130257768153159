import END_POINT from "../api/END_POINTS";
import axios from "../clients/axiosInstance";

export const submitUserAnswer = (
	event_id: number,
	user_id: number,
	question_id: number,
	selected_option: number[]
) => {
	const form = new FormData();

	form.append("user_id", user_id.toString());
	form.append("question_id", question_id.toString());

	selected_option.forEach((item, i) => {
		form.append(`selected_options[${i}]`, item.toString());
	});

	return axios.post(END_POINT.V1.SUBMIT_ANSWER(event_id), form);
};
