import { atom, AtomEffect } from "recoil";

type userStoreTypes = {
  firstName: string;
  lastName: string;
  imgUrl: string;
  user_id: string;
  proficiency: string;
  mobileNumber: string;
};

export const defaultUserStore = {
  firstName: "",
  lastName: "",
  imgUrl: "",
  user_id: "",
  proficiency: "",
  mobileNumber: "",
};

const localStorageEffect =
  (key: string): AtomEffect<userStoreTypes> =>
    ({
      setSelf,
      onSet,
    }) => {
      const savedValue = localStorage.getItem(key);
      if (savedValue != null) {
        setSelf(JSON.parse(savedValue));
      }

      onSet((newValue, _, isReset) => {
        isReset
          ? localStorage.removeItem(key)
          : localStorage.setItem(key, JSON.stringify(newValue));
      });
    };

const userStore = atom<userStoreTypes>({
  key: "userStore",
  default: defaultUserStore,
  effects: [localStorageEffect("user_info")],
});

export default userStore;
