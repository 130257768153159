import React from "react";

const Bell = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={62}
		height={63}
		fill="none"
		{...props}
	>
		<path
			fill="#fff"
			d="m53.92 40.105-9.744 15.9a13.523 13.523 0 0 1-9.997 6.4 13.464 13.464 0 0 1-9.372-2.371A12.247 12.247 0 0 1 7.526 43.07l-3.398-3.392a13.599 13.599 0 0 1 2.467-21.183l14.898-9.212a22.886 22.886 0 0 1 26.678 1.423l4.286-4.286a2.583 2.583 0 1 1 3.653 3.653l-4.28 4.283a23.123 23.123 0 0 1 2.09 25.748ZM20.87 56.422l-9.608-9.61a7.029 7.029 0 0 0 1.196 8.432 7.303 7.303 0 0 0 8.411 1.178Zm25.628-40.3a17.791 17.791 0 0 0-22.26-2.447L9.314 22.887a8.435 8.435 0 0 0-1.53 13.14L26.618 54.86a8.434 8.434 0 0 0 13.157-1.55l9.7-15.823a17.92 17.92 0 0 0-2.975-21.38v.014ZM49.803 62.5a2.583 2.583 0 0 1-1.527-4.67 22.302 22.302 0 0 0 8.564-13.968 2.582 2.582 0 1 1 5.045 1.11 27.642 27.642 0 0 1-10.56 17.032 2.584 2.584 0 0 1-1.522.496ZM2.618 15.326a2.583 2.583 0 0 1-2.067-4.118A27.621 27.621 0 0 1 17.893.552a2.584 2.584 0 0 1 1.034 5.063A22.31 22.31 0 0 0 4.685 14.28a2.583 2.583 0 0 1-2.067 1.046Z"
		/>
	</svg>
);
export default Bell;
