import { atom } from "recoil";

export type CurrentlySortType = "score" | "scale";

const currentlySort = atom<CurrentlySortType>({
	key: "currentlySort",
	default: "score",
});

export default currentlySort;
