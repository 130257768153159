import { useRecoilState, useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { Button, useToast } from "@chakra-ui/react";
import userAnswers from "../store";
import { useTranslation } from "react-i18next";
import { submitAnswer } from "../../../../lib/services/quiz";
import userStore from "../../../../store/userStore";
import { useState } from "react";
import eventState from "../../../../store/eventState";
import currentlyShow from "./currentlyShow.store";
import answerStatusStore from "./answerStatus.store";

const Controls = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const toast = useToast();

	const [show, setShow] = useRecoilState(currentlyShow);
	const eState = useRecoilValue(eventState);
	const [carouselData, setCarouselData] = useRecoilState(userAnswers);
	const [, setAnswerStatus] = useRecoilState(answerStatusStore);
	const userStorage = useRecoilValue(userStore);

	const [loading, setLoading] = useState(false);

	const handleClick = (
		question_id: number
		// state: -1 | 0 | 1,
		// direction: "forward" | "backward"
	) => {
		const currentAnswer =
			carouselData.answers[carouselData.currentIndex].userSelect;
		if (show === "question") {
			setLoading(true);
			submitAnswer(
				eState.activeContentId,
				+userStorage.user_id,
				question_id,
				currentAnswer === -1 ? 0 : 1
			)
				.then((res) => {
					setAnswerStatus({
						state: res.data.data.correct as boolean,
						text: res.data.data.message as string,
					});
					setShow("result");
				})
				.catch(() => {
					toast({
						title: t("Something went wrong") + ".",
						description: t("Please try again") + ".",
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				})
				.finally(() => setLoading(false));
		} else if (show === "result") {
			if (carouselData.currentIndex < carouselData.total - 1) {
				setShow("question");
				setCarouselData((prev) => ({
					...prev,
					currentIndex: prev.currentIndex + 1,
				}));
			} else {
				navigate("../show-result", {
					relative: "path",
				});
			}
		}
	};

	return (
		<div className="flex items-center justify-between md:justify-end gap-5">
			<Button
				className={`violet-btn base-btn !px-[60px] !py-[13px] !text-lg leading-[21.8px] font-bold w-full md:!w-fit`}
				size="md"
				isDisabled={
					(carouselData.answers[carouselData.currentIndex].userSelect === 0 &&
						show === "question") ||
					loading
				}
				onClick={() =>
					handleClick(+carouselData.data[carouselData.currentIndex].id)
				}
			>
				{carouselData.currentIndex === carouselData.total - 1
					? t("Send")
					: t("Next")}
			</Button>
		</div>
	);
};

export default Controls;
