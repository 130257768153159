import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { isLastActivity } from "../../lib/services/users";

const ActivityEnd = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	useEffect(() => {
		isLastActivity()
			.then((data) => {
				if (data.data.data) {
					setTimeout(() => {
						navigate("/activity/reminder");
					}, 2000);
				} else {
					setTimeout(() => {
						navigate("/event/activity/lobby");
					}, 2000);
				}
			})
			.finally(() => {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div
			className="h-[calc(100dvh-60px)] flex flex-col items-center justify-center gap-4 md:gap-[50px] text-center px-4"
			style={{
				backgroundImage: "url('https://bayer-be.axensodev.com/images/bg.png')",
				backgroundAttachment: "fixed",
				backgroundPosition: "center",
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
			}}
		>
			<div className="text-white rounded-[20px] bg-theme-blue p-8 flex items-center flex-col gap-4 md:gap-[10px] w-full md:max-w-[760px]">
				<p className="text-2xl leading-7 md:text-[28px] md:leading-[34px]">
					{t("What’s next")}
				</p>
				<h2 className="text-[30px] leading-9 md:text-[42px] md:leading-[50px] font-bold">
					{t("Il modulo è stato completato da alcuni utenti. Attendo che tutti completino il modulo.")}
				</h2>
				<p className="text-base leading-5 text-white tracking-[0.01px]">
					{t(
						"Grazie mille per aver giocato con noi! A breve saranno disponibili i risultati, vieni allo stand Bayer per commentarli insieme!"
					)}
				</p>
			</div>
		</div>
	);
};

export default ActivityEnd;
