const SlideShow = () => {
  return (
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.75 2C0.75 1.30964 1.30964 0.75 2 0.75H7C7.69036 0.75 8.25 1.30964 8.25 2V9C8.25 9.69036 7.69036 10.25 7 10.25H2C1.30964 10.25 0.75 9.69036 0.75 9V2ZM11.75 4C11.75 3.30964 12.3096 2.75 13 2.75H18C18.6904 2.75 19.25 3.30964 19.25 4V7C19.25 7.69036 18.6904 8.25 18 8.25H13C12.3096 8.25 11.75 7.69036 11.75 7V4ZM0.75 15C0.75 14.3096 1.30964 13.75 2 13.75H7C7.69036 13.75 8.25 14.3096 8.25 15V18C8.25 18.6904 7.69036 19.25 7 19.25H2C1.30964 19.25 0.75 18.6904 0.75 18V15ZM11.75 13C11.75 12.3096 12.3096 11.75 13 11.75H18C18.6904 11.75 19.25 12.3096 19.25 13V20C19.25 20.6904 18.6904 21.25 18 21.25H13C12.3096 21.25 11.75 20.6904 11.75 20V13Z"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default SlideShow;
