const Survey = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="*" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.99984 1H21M5.99984 7.66667H21M5.99984 14.3333H21M5.99984 21H21M1 1L1 21"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Survey;
