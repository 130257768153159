import Chip from "../../shared/Chip";
import Circle from "../../shared/Circle";
import Like from "../../icons/Like";
import DisLike from "../../icons/DisLike";

import { useRecoilState, useRecoilValue } from "recoil";
import slideShowStore from "../../pages/activities/slideshow/store";
import { react } from "../../lib/services/slideshow";
import userStore from "../../store/userStore";

const Reaction = () => {
	const [slide, setSlide] = useRecoilState(slideShowStore);
	const { user_id } = useRecoilValue(userStore);

	if (slide.total < 1) {
		return <></>;
	}

	const handleClickLike = () => {
		react(slide.slides[slide.currentIndex].id, true, +user_id).then(() => {
			setSlide((prev) => ({
				...prev,
				slides: prev.slides.map((slide, i) => {
					if (i !== prev.currentIndex) {
						return slide;
					}

					return {
						...slide,
						reaction: true,
					};
				}),
			}));
		});
	};

	const handleClickDisLike = () => {
		react(slide.slides[slide.currentIndex].id, false, +user_id).then(() => {
			setSlide((prev) => ({
				...prev,
				slides: prev.slides.map((slide, i) => {
					if (i !== prev.currentIndex) {
						return slide;
					}

					return {
						...slide,
						reaction: false,
					};
				}),
			}));
		});
	};

	return (
		<Chip className="flex items-center gap-4 self-stretch flex-row">
			<Circle
				className={`cursor-pointer border-[1px] border-violet-600 ${
					slide.slides[slide.currentIndex].reaction &&
					slide.slides[slide.currentIndex].reaction !== -1
						? "bg-violet-600"
						: "bg-white"
				}  p-[10px]`}
				onClick={handleClickLike}
			>
				<Like
					width={30}
					height={30}
					className={`md:hidden ${
						slide.slides[slide.currentIndex].reaction === true
							? "!fill-white"
							: "!fill-violet-600"
					}`}
				/>
				<Like
					className={`hidden md:block ${
						slide.slides[slide.currentIndex].reaction === true
							? "!fill-white"
							: "!fill-violet-600"
					}`}
				/>
			</Circle>
			<Chip className="md:hidden flex items-center self-stretch bg-dark-extra px-5 text-lg font-bold text-dolphin-700">
				{slide.currentIndex + 1}/{slide.total}
			</Chip>
			<Circle
				className={`cursor-pointer border-[1px] border-orange-600 ${
					!slide.slides[slide.currentIndex].reaction
						? "bg-orange-600"
						: "bg-white"
				}  p-[10px]`}
				onClick={handleClickDisLike}
			>
				<DisLike
					width={30}
					height={30}
					className={`md:hidden ${
						slide.slides[slide.currentIndex].reaction === false
							? "!fill-white"
							: "!fill-orange-600"
					}`}
				/>
				<DisLike
					className={`hidden md:block ${
						slide.slides[slide.currentIndex].reaction === false
							? "!fill-white"
							: "!fill-orange-600"
					}`}
				/>
			</Circle>
			<Chip className="hidden md:flex items-center self-stretch bg-dark-extra px-5 text-lg font-bold text-dolphin-700">
				{slide.currentIndex + 1}/{slide.total}
			</Chip>
		</Chip>
	);
};

export default Reaction;
