/* eslint-disable react-hooks/exhaustive-deps */
// import eventState from "../../store/eventState";
// import { useState } from "react";
import { useRecoilState } from "recoil";
import eventState from "../../store/eventState";
import slideShowStore, {
	defaultSlideShowStore,
} from "../../pages/activities/slideshow/store";
import { useNavigate } from "react-router-dom";
import resultsStore from "../../pages/activities/slideshow/slideShowResult/store";
import Pusher from "pusher-js/types/src/core/pusher";

export type Actions = "start" | "show" | "end" | "show_results";

type MsgType = {
	data: {
		content_id: number;
		content_type: number;
		content_type_name: "Slideshow";
		action: Actions;
		showable_content: {
			id: number;
			title: string;
			description: string;
			status: number;
			slide_image: string;
			sort_order: number;
			content_id: number;
			created_at: string;
			updated_at: string;
		};
		results: {
			liked: {
				id: number;
				total: number;
				title: string;
				slide_image: string;
			}[];
			disliked: {
				id: number;
				total: number;
				title: string;
				slide_image: string;
			}[];
		};
		slides: {
			title: string;
			description: string;
			content_id: number;
			slide_image: string;
			id: number;
		}[];
	};
	eventType: string;
};

const useSlideshowChannel = () => {
	const navigate = useNavigate();

	const [, setSlides] = useRecoilState(slideShowStore);
	const [, setState] = useRecoilState(eventState);
	const [, setResultsStore] = useRecoilState(resultsStore);
	// const [count, setCount] = useState(1);
	const channel_name = "private-presenter";

	const handleStartSlideShow = (data: MsgType) => {
		setState((prev) => ({
			...prev,
			activity: {
				activityName: data.data.content_type_name,
				activityType: data.data.content_type,
			},
			activeContentId: data.data.content_id,
			activityName: data.data.content_type_name,
			showHeaderControl: false,
		}));
		const slides = data.data.slides.map((item) => ({
			description: item.description,
			id: item.id.toString(),
			imgUrl: item.slide_image,
			reaction: -1 as -1 | boolean,
			title: item.title,
		}));
		setSlides({
			currentIndex: 0,
			slides: slides,
			total: slides.length,
		});
	};

	const handleInitSlideshow = (pusher: Pusher) => {
		const slideShowChannel = pusher.subscribe(channel_name);
		// console.log("this must appear once slideshow", count);
		// setCount((prev) => prev + 1);
		slideShowChannel.bind("slideshow", (data: MsgType) => {
			switch (data.data.action) {
				case "start":
					handleStartSlideShow(data);
					setState((prev) => ({
						...prev,
						showHeaderControl: true,
					}));
					navigate(
						`/event/activity/${data.data.content_type}/slideshow/player`
					);
					break;
				case "show":
					setState((prev) => ({
						...prev,
						showHeaderControl: true,
						activeContentId: data.data.content_id,
						activity: {
							activityName: data.data.content_type_name,
							activityType: data.data.content_type,
						},
						// activityTitle: data.data.content_title,
						// description: data.data.content_description,
					}));
					setSlides((prev) => ({
						...prev,
						currentIndex: data.data.slides.findIndex(
							(item) => item.id === data.data.showable_content.id
						),
					}));
					navigate(
						`/event/activity/${data.data.content_type}/slideshow/player`
					);
					break;
				case "end":
					setResultsStore({
						disliked: data.data.results.disliked,
						liked: data.data.results.liked,
					});
					setState((prev) => ({
						...prev,
						showHeaderControl: false,
					}));
					navigate(`/event/activity/${data.data.content_type}/slideshow/end`);
					break;
				case "show_results":
					setSlides(defaultSlideShowStore);
					navigate(
						`/event/activity/${data.data.content_type}/slideshow/result`
					);
					break;
				default:
					break;
			}
		});

		// slideShowChannel.bind(
		// 	"content-end",
		// 	(_: { data: []; eventType: "content-end" }) => {
		// 		setState((prev) => ({
		// 			...prev,
		// 			activeContentId: -1,
		// 			activity: undefined,
		// 			showHeaderControl: false,
		// 			activityTitle: "",
		// 		}));
		// 		navigate("/event/activity");
		// 	}
		// );
	};

	return {
		handleInitSlideshow,
	};
};

export default useSlideshowChannel;
