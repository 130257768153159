import Quiz from "../../../icons/Quiz";
import { useTranslation } from "react-i18next";

const QuizShowResults = () => {
	const { t } = useTranslation();

	return (
		<div className="bg-blaze-600 flex-1 md:mx-5 md:mb-5 mx-2 mb-2 p-2 rounded flex flex-col items-center justify-center gap-[50px]">
			<p className="scale-[2.2] fill-white">
				<Quiz />
			</p>
			<div className="flex flex-col gap-[10px]">
				<div className="text-white max-w-[820px] text-[30px] text-center leading-9">
					<h3>{t("Well done Quiz")}!</h3>
				</div>
				<p className="text-lg text-white leading-[21px] text-center">
					{t(
						"The quiz is about to be completed by some users. Wait for everyone to finish"
					)}
					.
				</p>
			</div>
		</div>
	);
};

export default QuizShowResults;
