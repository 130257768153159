import { useTranslation } from "react-i18next";

const Reminder = () => {
	const { t } = useTranslation();

	return (
		<div
			className="h-dvh flex flex-col items-center justify-center gap-4 md:gap-[50px] text-center px-4"
			style={{
				backgroundImage: "url('https://bayer-be.axensodev.com/images/bg.png')",
				backgroundAttachment: "fixed",
				backgroundPosition: "center",
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
			}}
		>
			<div className="text-white rounded-[20px] bg-theme-blue p-8 flex items-center flex-col gap-4 md:gap-[10px] w-full md:max-w-[760px]">
				<p className="text-3xl font-bold leading-9 md:text-[28px] md:leading-[34px] text-balance">
					{t("Check in at the stand to find out if you've won!")}
				</p>
				<p className="text-base leading-5 text-white tracking-[0.01px]">
					{t(
						"At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores"
					)}
				</p>
			</div>
		</div>
	);
};

export default Reminder;
