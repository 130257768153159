/* eslint-disable no-dupe-keys */
export const italianStrings = {
	// admin
	//#region header
	ONAIR: "ONLINE",
	OFFLINE: "OFFLINE",
	USER: "UTENTI",
	ONLINE: "ONLINE",
	//#endregion header

	//#region modal
	"Are you sure want to stop sharing?":
		"Sei sicuro di voler iniziare a condividere?",
	"You’re going to start sharing": "Stai per condividere",
	Stop: "Stop",
	"Start Sharing": "Inizia a condividere",
	"Cancel Request": "Annulla",
	//#endregion modal

	//#region buzzer
	"Ask to answer": "Chiedi di rispondere",
	"Try Again": "Riprova",
	"Right answer!": "Risposta esatta!",
	"Wrong answer!": "Risposta errata!",
	"The winner is": "Il vincitore è",
	"Sorry, try again": "Ops, riprova",
	//#endregion buzzer

	//#region list
	"Display results": "Mostra risultati",
	"All users have finished!": "Tutti gli utenti hanno finito!",
	Open: "Apri",
	Start: "Inizia",
	"All element listed": "Tutti gli elementi sono ordinati",
	Statement: "Statement",
	"All element listed": "Tutti gli elementi sono ordinati",
	Save: "Save",
	"Drag and list element": "Trascina e ordina gli elementi",
	"Well done": "Grazie mille!",
	"Module is about to be completed by some users. Wait for everyone to finish":
		"Il modulo è stato completato da alcuni utenti. Attendi che tutti completino il modulo.",

	//#endregion list

	//#region quiz
	"Back to dashboard": "Torna alla dashboard",
	TRUE: "VERO",
	FALSE: "FALSO",
	"All users have voted!": "Tutti hanno votato",
	"Correct answer!": "Risposta corretta!",
	"Wrong answer!": "Risposta Errata",
	Send: "Invia",
	True: "Vero",
	False: "Falso",
	"The quiz is about to be completed by some users. Wait for everyone to finish":
		"A breve saranno disponibili i risultati, ti aspettiamo al nostro stand per commentarli insieme",

	//#endregion quiz

	//#region slideshow
	LIKES: "MI PIACE",
	DISLIKE: "NON MI PIACE",
	"Top liked and disliked slides": "Slide più votate",
	USED: "MOSTRATA",
	"ONLINE NOW": "ONLINE ADESSO",
	NEXT: "PROSSIMO",
	"NOT USED": "DA MOSTRARE",
	Prev: "Indietro",
	Next: "Avanti",
	"Presentation ended!": "Presentazione finita!",
	"Just few seconds before displaying all results":
		"Alcuni secondi prima di mostrare i risultati",

	//#endregion slideshow

	//#region survey
	"Navigating to results": "Vai ai risultati",
	Voting: "Votazioni in corso",
	Selected: "Selezionato",
	Select: "Seleziona",
	"The survey is about to be completed by some users. Wait for everyone to finish":
		"A breve saranno disponibili i risultati, ti aspettiamo al nostro stand per commentarli insieme",
	//#endregion survey

	//#region components
	"You’re not sharing content": "Non stai condividendo!",
	"I'm just looking to warm up": "Ok, voglio solo scaldarmi",
	Playlist: "Agenda",
	"Shared content": "Moduli visti",
	"All content": "Tutti i moduli",
	votes: "voti",
	Welcome: "ANMCO 2024",
	"Choose your avatar": "Scegli il tuo avatar",
	"Event is going to start": "L'evento inizierà a breve",
	"Emerging technologies in healthcare": "CARDIO QUIZ",
	"Be patient as the moderator commences the session":
		"Tra poco il moderatore avvierà la sessione",
	Join: "Partecipa!",
	"Please Fill Fields": "Completa i campi",
	"First Name": "Nome",
	"Last name": "Cognome",
	Login: "Login",
	"Welcome Back": "Bentornato",
	"Let's continue from where we started":
		"Riprendiamo da dove avevamo lasciato",
	"Account created": "Account creato",
	"We've created your account for you": "Abbiamo creato il tuo account",
	"Something went wrong": "Ops, qualcosa è andato storto :(",
	"Please full all inputs and try again": "Completa tutti i campi e riprova!",
	"Please try again": "Prova di nuovo",
	//#endregion components

	"Rising project": "Maggio 2024",
	"Ready to go": "Pronti a iniziare",
	"You’re online": "Sei online",
	"Admin is going to start sharing content asap":
		"Tra poco il moderatore avvierà la sessione",
	"Congrats you have found a secret place, unfortunately you have to go back":
		"Complimenti, hai trovato un angolo nascoto, ora torniamo indietro",
	Home: "Home",
	"Access to this page is restricted": "L'accesso a questa pagina è riservato",
	"Please check with the site admin if you believe this is a mistake":
		"Ti preghiamo di verificare con l'amministratore se pensi che questo sia un errore",
	"The Game is about to be completed by some users. Wait for everyone to finish":
		"Il modulo è stato completato da alcuni utenti. Attendi che tutti completino il modulo.",
	"first name must be 3 characters at least": "Inserisci almeno tre lettere",
	"last name must be 3 characters at least": "Inserisci almeno tre lettere",
	All: "Tutti",
	score: "Posizione",
	scale: "Punteggio",
	"Showing results sorted by": "Ordina i risultati per",

	"At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores":
		"Domande al ritmo del cuore",
	"Phone number": "Numero di telefono",
	"please enter a valid mobile number":
		"Ops, inserisci un numero di telefono valido!",
	"You are registered correctly. As soon as a new game is about to start you will be notified with a text message.":
		"Riceverai un sms appena il gioco inizierà, attendi con noi allo stand Bayer!",
	"Check in at the stand to find out if you've won!":
		"Vieni allo stand per scoprire i risultati",
	"Wait Until Admin Starts New Content": "Wait Until Admin Starts New Content",
	Close: "Chiudi",
	"What’s next": "ANMCO 2024",
	"Well done Survey": "Ottimo lavoro!",
	"Well done Quiz": "Ottimo lavoro!",
	"Il modulo è stato completato da alcuni utenti. Attendo che tutti completino il modulo.":
		"CARDIO QUIZ",
};
