import axios from "../../lib/clients/axiosInstance";
import END_POINTS from "../../lib/api/END_POINTS";
import { ReturnHome } from "./types/refetcher.api.types";
import { SurveyTypes } from "./types/survey.api.types";
import { useRecoilState, useRecoilValue } from "recoil";
import eventState from "../../store/eventState";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import allowPusher from "../../store/allowPusher.store";
import surveyState from "../../pages/activities/survey/store";
import surveyHandler from "./handlers/surveyHandler";
import userSubmission from "../../pages/activities/survey/activity/store";
import { QuizTypes } from "./types/quiz.types";
import answerStatusStore from "../../pages/activities/quiz/activity/answerStatus.store";
import currentlyShowQuiz from "../../pages/activities/quiz/activity/currentlyShow.store";
import userAnswers from "../../pages/activities/quiz/store";
import quizHandler from "./handlers/quizHandler";
import userStore, { defaultUserStore } from "../../store/userStore";
import tempData from "../../store/tempData.store";

const useReFetcher = (custom?: boolean) => {
	const [value, setValue] = useRecoilState(userStore);

	const { REFETCH } = END_POINTS.V1;

	const navigate = useNavigate();

	const [temp, setTemp] = useRecoilState(tempData);
	const [loading, setLoading] = useState(false);

	const [, setState] = useRecoilState(eventState);
	const [, setAllowPusher] = useRecoilState(allowPusher);

	// ! Survey state manager
	const [, setSurvey] = useRecoilState(surveyState);
	const [, setSubmissionData] = useRecoilState(userSubmission);

	// ! Quiz State Manager
	const [, setAnswerStatus] = useRecoilState(answerStatusStore);
	const [, setCurrentlyShowQuiz] = useRecoilState(currentlyShowQuiz);
	const [, setUserAnswer] = useRecoilState(userAnswers);

	const handleNavigate = () => {
		if (temp) {
			const { content_type_name } = temp?.data;
			let route: string;

			switch (content_type_name) {
				case "survey":
					route = surveyHandler(
						temp as SurveyTypes,
						setState,
						setSurvey,
						setSubmissionData
					);
					navigate(route);
					break;
				case "true/false":
					route = quizHandler(
						temp as QuizTypes,
						setAnswerStatus,
						setCurrentlyShowQuiz,
						setUserAnswer,
						setState
					);
					navigate(route);
					break;
				default:
					setState((prev) => ({
						...prev,
						showHeaderControl: false,
						activity: undefined,
					}));
					navigate("/event/activity/lobby");
					break;
			}
		}
	};

	const handleFetch = () => {
		setLoading(true);
		axios
			.post(REFETCH(value.user_id))
			.then((data: { data: ReturnHome | SurveyTypes | QuizTypes }) => {
				if (data.data) {
					setTemp(data.data);
				} else {
					console.log("no data");
				}
			})
			.catch((err) => {
				if (err && err.response) {
					if (
						err.response.data.code === 404 &&
						err.response.data.error === "No user Found"
					) {
						setValue(defaultUserStore);
						navigate("/");
					}
				}
			})
			.finally(() => {
				setLoading(false);
				setAllowPusher(true);
			});
	};

	const handleRefetch = () => {
		axios
			.post(REFETCH(value.user_id))
			.then((data: { data: ReturnHome | SurveyTypes | QuizTypes }) => {
				const { content_type_name } = data.data.data;
				let route: string;

				switch (content_type_name) {
					case "survey":
						if (data.data) {
							route = surveyHandler(
								data.data as SurveyTypes,
								setState,
								setSurvey,
								setSubmissionData
							);
							navigate(route);
						} else {
							navigate("/event/activity/lobby");
						}
						break;
					case "true/false":
						if (data.data) {
							route = quizHandler(
								data.data as QuizTypes,
								setAnswerStatus,
								setCurrentlyShowQuiz,
								setUserAnswer,
								setState
							);
							navigate(route);
						} else {
							navigate("/event/activity/lobby");
						}
						break;
					default:
						setState((prev) => ({
							...prev,
							showHeaderControl: false,
							activity: undefined,
						}));
						console.log("handle default");
						navigate("/event/activity/lobby");
						break;
				}
			})
			.catch((err) => {
				if (err && err.response) {
					if (
						err.response.data.code === 404 &&
						err.response.data.error === "No user Found"
					) {
						setValue(defaultUserStore);
						navigate("/");
					}
				}
			})
			.finally(() => setAllowPusher(true));
	};

	useEffect(() => {
		if (!custom) {
			handleRefetch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { handleFetch, handleNavigate, loading, handleRefetch, temp };
};

export default useReFetcher;
