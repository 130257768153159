const Bars = () => {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="stroke-none"
		>
			<path
				d="M8 4C7.46957 4 6.96086 4.21072 6.58579 4.58579C6.21072 4.96086 6 5.46957 6 6V14C6 14.5304 6.21072 15.0391 6.58579 15.4142C6.96086 15.7893 7.46957 16 8 16C8.53044 16 9.03914 15.7893 9.41422 15.4142C9.78929 15.0391 10 14.5304 10 14V6C10 5.46957 9.78929 4.96086 9.41422 4.58579C9.03914 4.21072 8.53044 4 8 4Z"
				fill="#9747FF"
			/>
			<path
				d="M14 0C13.4696 0 12.9609 0.210714 12.5858 0.585786C12.2107 0.960859 12 1.46957 12 2V14C12 14.5304 12.2107 15.0391 12.5858 15.4142C12.9609 15.7893 13.4696 16 14 16C14.5304 16 15.0391 15.7893 15.4142 15.4142C15.7893 15.0391 16 14.5304 16 14V2C16 1.46957 15.7893 0.960859 15.4142 0.585786C15.0391 0.210714 14.5304 0 14 0Z"
				fill="#9747FF"
			/>
			<path
				d="M2 8C1.46957 8 0.960859 8.21072 0.585786 8.58579C0.210714 8.96086 0 9.46957 0 10V14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16C2.53043 16 3.03914 15.7893 3.41421 15.4142C3.78928 15.0391 4 14.5304 4 14V10C4 9.46957 3.78928 8.96086 3.41421 8.58579C3.03914 8.21072 2.53043 8 2 8Z"
				fill="#9747FF"
			/>
		</svg>
	);
};

export default Bars;
