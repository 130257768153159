import Rocket from "../../../../icons/Rocket";
import Bell from "../../../../icons/Bell";
import { useTranslation } from "react-i18next";

const Result = ({
	answer,
	message,
}: {
	answer: boolean | undefined;
	message: string | null;
}) => {
	const { t } = useTranslation();

	return (
		<div
			className={`w-full flex-1 flex justify-center items-center text-white gap-5 flex-col rounded p-4
         ${
						answer ? "bg-green-600" : "bg-orange-600"
					} h-[calc(100dvh-60px-8px-54px-20px)] md:h-[calc(100dvh-106px-20px-54px-20px)] text-center`}
		>
			<div className="p-5 fill-white">{answer ? <Rocket /> : <Bell />}</div>
			<p className="text-2xl leading-7 font-bold">
				{answer !== undefined && answer
					? t("Correct answer!")
					: t("Wrong answer!")}
			</p>
			<p className="max-w-sm text-base leading-5">{message}</p>
		</div>
	);
};

export default Result;
