import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import "swiper/css";
import surveyState from "../store";
import ResultCard from "./ResultCard";

const Result = () => {
	const swiperRef = useRef<SwiperClass | null>(null);
	const [surveyData] = useRecoilState(surveyState);

	useEffect(() => {
		if (swiperRef.current) {
			swiperRef?.current?.slideTo(surveyData.currentIndex);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [surveyData.currentIndex]);

	return (
		<div className="flex-1 h-full">
			<Swiper
				onSwiper={(swiper: SwiperClass) => {
					swiperRef.current = swiper;
				}}
				spaceBetween={50}
				slidesPerView={1}
				autoplay={false}
				draggable={false}
				allowTouchMove={false}
			>
				{surveyData.questions.map((sur, j) => {
					const results = sur.options.map((item) => +item.option_progress);
					return (
						<SwiperSlide key={sur.id} >
							<ResultCard
								currentSlide={j + 1}
								results={results}
								surveyData={sur}
								totalSlides={surveyData.total}
								key={sur.id}
							/>
						</SwiperSlide>
					);
				})}
			</Swiper>
		</div>
	);
};

export default Result;

// eslint-disable-next-line no-lone-blocks
{
	/* <Chip className="!roundedxl bg-dolphin-100 p-5 flex flex-col gap-10 w-full h-full justify-between">
	<div className="flex flex-col gap-5">
		<Chip className="px-4 py-[6px] text-lg text-white !bg-dark-mid w-fit">
			{j + 1}/{surveyData.total}
		</Chip>
		<h3 className="text-[30px] font-bold text-dolphin-700 leading-9">
			{sur.question}
		</h3>
		<p
			className="text-[24px] text-dark-light pt-[5px] leading-7"
			dangerouslySetInnerHTML={{ __html: sur.description }}
		/>
	</div>
	<div className="flex items-center gap-5 overflow-x-scroll">
		{sur.options.map((op, i) => {
			return (
				<Option
					key={i}
					selectable={false}
					percentage={op.option_progress}
					maxed={theHighestVal === op.option_progress}
					option={op.option}
				/>
			);
		})}
	</div>
</Chip> */
}
