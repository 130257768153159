import { atom } from "recoil";

export type slideShowTypes = {
  currentIndex: number,
  total: number,
  slides: {
    id: string,
    title: string,
    description: string,
    imgUrl: string,
    reaction: boolean | -1
  }[]
}

export const defaultSlideShowStore = {
  currentIndex: 0,
  total: 0,
  slides: [],
}

const slideShowStore = atom<slideShowTypes>({
  key: "slideShowStore",
  default: defaultSlideShowStore,
});

export default slideShowStore;