import * as React from "react";

const StarsIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={182}
		height={182}
		fill="none"
		{...props}
	>
		<path
			fill="#fff"
			d="M37.916 106.167a7.583 7.583 0 1 0 0-15.166 7.583 7.583 0 0 0 0 15.166ZM7.583 75.835a7.583 7.583 0 1 0 0-15.167 7.583 7.583 0 0 0 0 15.167ZM37.916 45.5a7.583 7.583 0 1 0 0-15.168 7.583 7.583 0 0 0 0 15.167ZM7.583 15.167A7.583 7.583 0 1 0 7.583 0a7.583 7.583 0 0 0 0 15.167ZM174.417 106.167a7.583 7.583 0 1 0 0-15.166 7.584 7.584 0 0 0 0 15.166ZM144.084 75.835a7.583 7.583 0 1 0 .001-15.167 7.583 7.583 0 0 0-.001 15.167ZM174.417 45.5a7.583 7.583 0 0 0 0-15.168 7.584 7.584 0 0 0 0 15.167ZM144.084 15.167A7.583 7.583 0 1 0 144.085 0a7.583 7.583 0 0 0-.001 15.167ZM106.166 182a7.583 7.583 0 1 0 0-15.167 7.584 7.584 0 0 0 0 15.167ZM75.834 151.668a7.583 7.583 0 1 0 0-15.167 7.583 7.583 0 0 0 0 15.167ZM106.166 121.335a7.583 7.583 0 1 0 0-15.166 7.583 7.583 0 1 0 0 15.166ZM75.834 91a7.583 7.583 0 1 0 0-15.167 7.583 7.583 0 0 0 0 15.166ZM113.752 22.75A15.168 15.168 0 0 1 98.585 7.583a7.583 7.583 0 1 0-15.167 0A15.167 15.167 0 0 1 68.252 22.75a7.583 7.583 0 0 0 0 15.167 15.167 15.167 0 0 1 15.166 15.167 7.583 7.583 0 0 0 15.167 0 15.168 15.168 0 0 1 15.167-15.167 7.583 7.583 0 0 0 0-15.167ZM53.084 144.083a15.168 15.168 0 0 1-15.167-15.167 7.583 7.583 0 0 0-15.167 0 15.166 15.166 0 0 1-15.167 15.167 7.584 7.584 0 0 0 0 15.167 15.168 15.168 0 0 1 15.167 15.167 7.583 7.583 0 0 0 15.167 0 15.166 15.166 0 0 1 15.167-15.167 7.584 7.584 0 0 0 0-15.167ZM174.417 144.083a15.168 15.168 0 0 1-15.167-15.167 7.583 7.583 0 0 0-15.167 0 15.168 15.168 0 0 1-15.167 15.167 7.583 7.583 0 0 0 0 15.167 15.168 15.168 0 0 1 15.167 15.167 7.583 7.583 0 0 0 15.167 0 15.168 15.168 0 0 1 15.167-15.167 7.583 7.583 0 0 0 0-15.167Z"
		/>
	</svg>
);
export default StarsIcon;
