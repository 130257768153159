/* eslint-disable react-hooks/exhaustive-deps */
// import { useState } from "react";
import { useRecoilState } from "recoil";
import eventState from "../../store/eventState";
import { useNavigate } from "react-router-dom";
import Pusher from "pusher-js/types/src/core/pusher";
import resultStore from "../../pages/activities/game/store/results";

export type Actions =
	| "start"
	| "show"
	| "end"
	| "show_results"
	| "prev"
	| "next"
	| "select-user";

type normalMsgGame = {
	content_id: number;
	content_type: 6;
	content_type_name: "html-game";
	action: Actions;
	showable_content: {
		id: number;
		title: string;
		description: string;
		type: 6;
		type_name: "HTML-Game";
		url: string;
	};
	results: {
		score: { text: string; percentage: string }[];
		overall_progress: "";
	};
};

type MsgType = {
	data: normalMsgGame;
	eventType: string;
};

const useGameChannel = () => {
	const navigate = useNavigate();

	const [, setState] = useRecoilState(eventState);
	const [, setResults] = useRecoilState(resultStore);

	// const [count, setCount] = useState(1);

	const channel_name = "private-presenter";

	const handleStartEvent = (data: MsgType) => {
		const cData = data.data as normalMsgGame;
		setState((prev) => ({
			...prev,
			activity: {
				activityName: cData.content_type_name,
				activityType: cData.content_type,
			},
			activeContentId: cData.content_id,
			activityName: cData.content_type_name,
			showHeaderControl: true,
		}));
	};

	const handleInitGame = (pusher: Pusher) => {
		const gameChannel = pusher.subscribe(channel_name);

		// console.log("this must appear once game channel", count);
		// setCount((prev) => prev + 1);

		gameChannel.bind("html-game", (data: MsgType) => {
			switch (data.data.action) {
				case "start":
					handleStartEvent(data);
					navigate(
						`/event/activity/${data.data.content_type}/game/game-start`,
						{
							replace: true,
						}
					);
					break;
				case "show_results":
					setResults({
						overall_progress: data.data.results.overall_progress,
						score: data.data.results.score,
					});
					navigate(
						`/event/activity/${data.data.content_type}/game/results`
					);
					break;
				default:
					break;
			}
		});

		// gameChannel.bind(
		// 	"content-end",
		// 	(_: { data: []; eventType: "content-end" }) => {
		// 		setState((prev) => ({
		// 			...prev,
		// 			activeContentId: -1,
		// 			activity: undefined,
		// 			showHeaderControl: false,
		// 			activityTitle: "",
		// 		}));
		// 		navigate("/event/activity");
		// 	}
		// );
	};

	return {
		handleInitGame,
	};
};

export default useGameChannel;
