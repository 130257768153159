import { RouterProvider } from "react-router-dom";
import useRouter from "./router/router";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import { RecoilRoot } from "recoil";

import "@fontsource/fira-sans";

import "@fontsource/fira-sans/100.css";
import "@fontsource/fira-sans/400.css";
import "@fontsource/fira-sans/700.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});

function App() {
	const routes = useRouter();

	return (
		<QueryClientProvider client={queryClient}>
			<ChakraProvider theme={theme}>
				<RecoilRoot>
					<RouterProvider router={routes} />
				</RecoilRoot>
			</ChakraProvider>
		</QueryClientProvider>
	);
}

export default App;
