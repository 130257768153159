import { useEffect } from "react";
import StartScreen from "../../components/StartScreen";
import useReFetcher from "../../hooks/reFetcher/useRefetcher";
import { FaSpinner } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { checkUserExists } from "../../lib/services/users";
import { useRecoilState } from "recoil";
import userStore, { defaultUserStore } from "../../store/userStore";

const ActivityStartScreen = () => {
	const { handleFetch, loading, temp } = useReFetcher(true);
	const navigate = useNavigate();
	const [{ user_id }, setState] = useRecoilState(userStore);

	const { isLoading, isError } = useQuery({
		queryKey: ["user-exists"],
		queryFn: () => checkUserExists(user_id),
		retry: false,
	});

	useEffect(() => {
		if (!isLoading && !isError) {
			handleFetch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	useEffect(() => {
		if (!isLoading && !isError) {
			if (temp) {
				if (
					temp?.data.event_data.action === "back-to-home" ||
					temp?.data.event_data.action === "end"
				) {
					navigate("/event/activity/lobby");
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [temp, isLoading]);

	useEffect(() => {
		if (isError) {
			setState(defaultUserStore);
			navigate("/");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isError]);

	return (
		<div
			className="h-dvh flex items-center justify-center p-4"
			style={{
				backgroundImage: "url('https://bayer-be.axensodev.com/images/bg.png')",
				backgroundAttachment: "fixed",
				backgroundPosition: "center",
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
			}}
		>
			{loading ? (
				<FaSpinner className="animate-spin duration-300" />
			) : (
				<StartScreen isInternalLoad={isLoading} />
			)}
		</div>
	);
};

export default ActivityStartScreen;
