import Title from "./Title";
import ActivityIndicator from "./ActivityIndicator";
import ParticipantViewer from "./ParticipantViewer";
import ActivityControl from "./ActivityControl";
import UserControls from "./UserControls";
import UserAvatar from "./userControl/UserAvatar";
import EventStatus from "./userControl/EventStatus";
import Chip from "../../shared/Chip";
import ActivityLabel from "./ActivityLabel";

const Header = () => {
	return (
		<header className="sticky left-0 top-0 z-50 bg-white p-2 md:p-5">
			<div className="hidden md:flex w-full items-center gap-5">
				<UserControls />
				<ParticipantViewer />
				<Title />
				<ActivityIndicator />
				<ActivityControl />
			</div>
			<div className="flex md:hidden items-center justify-between">
				<div className="flex items-center gap-1">
					<ActivityLabel />

					<Chip>
						<UserAvatar />
					</Chip>
				</div>
				<div className="flex items-center gap-1">
					<Chip>
						<EventStatus />
					</Chip>
					<ParticipantViewer />
					<ActivityControl />
				</div>
			</div>
		</header>
	);
};

export default Header;
