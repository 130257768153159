/* eslint-disable react-hooks/exhaustive-deps */
// import { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import eventState from "../../store/eventState";
import { useNavigate } from "react-router-dom";
import userStore from "../../store/userStore";
import buzzerStore from "../../pages/activities/buzzer/store/buzzerStore";
import Pusher from "pusher-js/types/src/core/pusher";

export type Actions =
	| "start"
	| "show"
	| "end"
	| "show_results"
	| "prev"
	| "next"
	| "select-user";

type normalMsgBuzzer = {
	content_id: number;
	content_type: number;
	content_type_name: string;
	action: Actions;
	showable_content: {
		winner: [];
		questions: {
			id: number;
			text: string;
			description: string;
			buzzer_options: {
				id: number;
				text: string;
			}[];
		}[];
	};
	question_id: null;
	results: {
		answered_users: [];
		winner: {
			first_name: string;
			id: string;
			image: string;
			last_name: string;
		}[];
	};
};

type scoreMsgBuzzer = {
	data: {
		result: boolean;
		option: string;
		question: string;
	};
};

type MsgType = {
	data: normalMsgBuzzer;
	eventType: string;
};

type MsgTypeSelect = {
	data: {
		selected_user: number;
		question: number;
		content: {
			id: number;
			type: 4;
			type_name: "Buzzer";
			title: string;
			description: string;
			questions: {
				id: number;
				text: string;
				description: string;
				buzzer_options: { id: number; text: string }[];
			}[];
		};
	};
	eventType: string;
	channel: "buzzer";
};

const useBuzzerChannel = () => {
	const navigate = useNavigate();

	const userKey = useRecoilValue(userStore);

	const [, setState] = useRecoilState(eventState);
	const [, setBuzzer] = useRecoilState(buzzerStore);
	// const [count, setCount] = useState(1);

	const channel_name = "private-presenter";

	const handleStartEvent = (data: MsgType) => {
		const cData = data.data as normalMsgBuzzer;
		setState((prev) => ({
			...prev,
			activity: {
				activityName: cData.content_type_name,
				activityType: cData.content_type,
			},
			activeContentId: cData.content_id,
			activityName: cData.content_type_name,
			showHeaderControl: true,
		}));
		setBuzzer({
			currentIndex: 0,
			questions: data.data.showable_content.questions.map((item) => ({
				description: item.description,
				question: item.text,
				id: item.id.toString(),
				options: item.buzzer_options.map((op) => ({
					id: op.id.toString(),
					option: op.text,
				})),
			})),
			userAnsweringId: -1,
			userIsAnswering: false,
			answerIsTrue: false,
			winner: [],
		});
	};

	const handleInitBuzzer = (pusher: Pusher) => {
		const buzzerChannel = pusher.subscribe(channel_name);

		// console.log("this must appear once list channel", count);
		// setCount((prev) => prev + 1);

		buzzerChannel.bind("buzzer", (data: MsgType) => {
			switch (data.data.action) {
				case "start":
					handleStartEvent(data);
					break;
				case "next":
					setBuzzer((prev) => ({
						...prev,
						currentIndex: prev.currentIndex + 1,
						showHeaderControl: true,
						userAnsweringId: -1,
						userIsAnswering: false,
						answerIsTrue: false,
					}));
					navigate(`/event/activity/${data.data.content_type}/buzzer`, {
						replace: true,
					});
					break;
				case "end":
					// set results here
					navigate(
						`/event/activity/${data.data.content_type}/buzzer/wait-results`
					);
					break;
				case "show_results":
					// const sData = data.data as normalMsg;
					navigate(
						`/event/activity/${data.data.content_type}/buzzer/show-result`
					);
					setBuzzer((prev) => ({
						...prev,
						winner: data.data.results.winner,
					}));
					// set results here
					// setListState((prev) => ({
					// 	...prev,
					// 	score: sData.results.score,
					// }));
					navigate(
						`/event/activity/${data.data.content_type}/buzzer/show-result`
					);
					break;
				default:
					break;
			}
		});

		buzzerChannel.bind("select-user", (data: MsgTypeSelect) => {
			const questions = data.data.content.questions.map((item) => ({
				question: item.text,
				description: item.description,
				id: item.id.toString(),
				options: item.buzzer_options.map((op) => ({
					id: op.id.toString(),
					option: op.text,
				})),
			}));

			setState((prev) => ({
				...prev,
				activity: {
					activityName: data.data.content.type_name,
					activityType: data.data.content.type,
				},
				activeContentId: data.data.content.id,
				activityName: data.data.content.type_name,
				activityTitle: data.data.content.title,
				description: data.data.content.description,
				showHeaderControl: true,
			}));

			setBuzzer((prev) => ({
				...prev,
				questions: questions,
				currentIndex: questions.findIndex(
					(item) => item.id === data.data.question.toString()
				),
				userAnsweringId: data.data.selected_user,
				userIsAnswering: true,
				answerIsTrue: false,
			}));

			if (userKey.user_id === data.data.selected_user.toString()) {
				navigate(`/event/activity/4/buzzer/active`);
			} else {
				setBuzzer((prev) => ({
					...prev,
				}));
				navigate(`/event/activity/4/buzzer/user-submitting`);
			}
		});

		buzzerChannel.bind("stop-user", (data: MsgTypeSelect) => {
			setBuzzer((prev) => ({
				...prev,
				currentIndex: data.data.content.questions.findIndex(
					(item) => item.id === data.data.question
				),
				questions: data.data.content.questions.map((item) => ({
					description: item.description,
					id: item.id.toString(),
					options: item.buzzer_options.map((op) => ({
						id: op.id.toString(),
						option: op.text,
					})),
					question: item.text,
				})),
				userAnsweringId: -1,
				userIsAnswering: false,
				answerIsTrue: false,
			}));
			setState((prev) => ({
				...prev,
				activityTitle: data.data.content.title,
				description: data.data.content.description,
				showHeaderControl: true,
				activity: {
					activityName: data.data.content.type_name,
					activityType: data.data.content.type,
				},
			}));

			navigate(`/event/activity/4/buzzer`);
		});

		buzzerChannel.bind("user-submit", (data: scoreMsgBuzzer) => {
			setBuzzer((prev) => ({
				...prev,
				userIsAnswering: false,
				answerIsTrue: data.data.result,
			}));
		});

		// buzzerChannel.bind(
		// 	"content-end",
		// 	(_: { data: []; eventType: "content-end" }) => {
		// 		setState((prev) => ({
		// 			...prev,
		// 			activeContentId: -1,
		// 			activity: undefined,
		// 			showHeaderControl: false,
		// 			activityTitle: "",
		// 		}));
		// 		navigate("/event/activity");
		// 	}
		// );
	};

	return {
		handleInitBuzzer,
	};
};

export default useBuzzerChannel;
