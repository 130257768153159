import {
	Button,
	FormControl,
	FormErrorMessage,
	Input,
	InputGroup,
	InputLeftAddon,
	useToast,
} from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import userStore from "../../../store/userStore";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useEffect } from "react";
import { Field, Formik } from "formik";

import Logo from "../../../icons/Logo";
import { useMutation } from "@tanstack/react-query";
import { joinEvent } from "../../../lib/services/users";

type LoginTypes = {
	firstName: string;
	lastName: string;
	mobileNumber: string;
};

const Login = () => {
	const toast = useToast();

	const [userInfo, setUserInfo] = useRecoilState(userStore);
	const navigate = useNavigate();

	const { t } = useTranslation();

	const { mutateAsync, isPending } = useMutation({
		mutationFn: ({ firstName, lastName, mobileNumber }: LoginTypes) => {
			return joinEvent(firstName, lastName, mobileNumber);
		},
	});

	useEffect(() => {
		if (userInfo.user_id.length > 0) {
			if (userInfo.firstName.length > 2 && userInfo.lastName.length > 2) {
				if (typeof userInfo.imgUrl === "string" && userInfo.imgUrl.length > 0) {
					navigate("/thanks");
				} else {
					navigate("/welcome");
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleLogin = (values: {
		firstName: string;
		lastName: string;
		mobileNumber: string;
	}) => {
		mutateAsync({ ...values, mobileNumber: `+39${values.mobileNumber}` })
			.then((data) => {
				const { user_id, image } = data.data.data;
				if (typeof user_id === "number") {
					if (typeof image === "string" && image.length > 3) {
						toast({
							title: `${t("Welcome Back")} ${userInfo.firstName}`,
							description: t("Let's continue from where we started") + "...",
							status: "success",
							duration: 3000,
							isClosable: true,
						});
						navigate("/thanks");
					} else {
						toast({
							title: t("Account created") + ".",
							description: t("We've created your account for you") + ".",
							status: "success",
							duration: 3000,
							isClosable: true,
						});
						setUserInfo((prev) => ({ ...prev, user_id: `${user_id}` }));
						navigate("/welcome");
					}
				} else {
					toast({
						title: t("Something went wrong") + ".",
						description: t("Please full all inputs and try again") + ".",
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				}
				setUserInfo((prev) => ({
					...prev,
					firstName: values.firstName,
					lastName: values.lastName,
					mobileNumber: values.mobileNumber,
				}));
				setUserInfo((prev) => ({
					...prev,
					user_id: `${user_id}`,
					imgUrl: image,
				}));
			})
			.catch((error) => {
				console.log();
				if (error.response.status === 401) {
					toast({
						title: t("Failed, invalid phone number") + ".",
						description: t("please enter a valid mobile number") + ".",
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				} else {
					toast({
						title: t("Something went wrong") + ".",
						description: t("Please full all inputs and try again") + ".",
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				}
			});
	};

	return (
		<div
			className="h-dvh w-full p-4 flex items-center justify-center flex-col gap-8"
			style={{
				backgroundImage: "url('https://bayer-be.axensodev.com/images/bg.png')",
				backgroundAttachment: "fixed",
				backgroundPosition: "center",
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
			}}
		>
			<div className="flex flex-col gap-4 bg-theme-blue rounded-5 p-8 text-white w-full max-w-[420px]">
				<p className="text-2xl leading-7 md:text-[28px] md:leading-[34px] text-center">
					{t("Please Fill Fields")}
				</p>
				<Formik
					initialValues={{
						firstName: userInfo.firstName || "",
						lastName: userInfo.lastName || "",
						mobileNumber: userInfo.mobileNumber || "",
					}}
					onSubmit={(values) => {
						handleLogin(values);
					}}
				>
					{({ handleSubmit, errors, touched }) => (
						<form
							onSubmit={(e) => {
								e.preventDefault();
								handleSubmit(e);
							}}
							className="flex flex-col items-center gap-5 w-full"
						>
							<FormControl isInvalid={!!errors.firstName && touched.firstName}>
								<Field
									as={Input}
									id="firstName"
									name="firstName"
									type="string"
									placeholder={t("First Name")}
									validate={(value: string) => {
										if (value.length < 3) {
											return t("first name must be 3 characters at least");
										}
									}}
									className="!rounded !w-full md:w-[428px]"
									variant={"login"}
								/>
								<FormErrorMessage className="place-content-center pt-1 w-full text-red-300">
									{errors.firstName}
								</FormErrorMessage>
							</FormControl>
							<FormControl isInvalid={!!errors.lastName && touched.lastName}>
								<Field
									as={Input}
									id="lastName"
									name="lastName"
									type="string"
									placeholder={t("Last name")}
									className="!rounded !w-full md:w-[428px]"
									validate={(value: string) => {
										if (value.length < 3) {
											return t("last name must be 3 characters at least");
										}
									}}
									variant={"login"}
								/>
								<FormErrorMessage className="place-content-center pt-1 w-full text-red-300">
									{errors.lastName}
								</FormErrorMessage>
							</FormControl>
							<FormControl
								isInvalid={!!errors.mobileNumber && touched.mobileNumber}
								className="relative"
							>
								<span className="bg-white rounded-l text-gray-600 px-2 absolute top-[calc(59px/2)] -translate-y-1/2 text-xs">
									+39
								</span>
								<Field
									as={Input}
									maxLength={11}
									id="mobileNumber"
									name="mobileNumber"
									type="string"
									inputmode="tel"
									placeholder={t("Phone number")}
									validate={(value: string) => {
										if (value.length < 9) {
											return t("please enter a valid mobile number");
										}
										// const pattern =
										// 	/^(\((00|\+)39\)|(00|\+)39)?(38[890]|34[7-90]|36[680]|33[3-90]|32[89])\d{7}$/;
										// if (!pattern.test(value)) {
										// 	return t("please enter a valid mobile number");
										// }
									}}
									className="!rounded w-full"
									variant={"login"}
								/>
								<FormErrorMessage className="place-content-center pt-1 w-full text-red-300">
									{errors.mobileNumber}
								</FormErrorMessage>
							</FormControl>

							<Button
								variant={"primary"}
								size="md"
								className="orange-btn w-full !py-[13px] md:!py-[18px]"
								type="submit"
								isLoading={isPending}
							>
								{t("Next")}
							</Button>
						</form>
					)}
				</Formik>
			</div>
			<Logo />
		</div>
	);
};

export default Login;
