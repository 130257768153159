import { atom } from "recoil";

export type AnswerStatusStoreType = {
	state: boolean | undefined;
	text: string | null;
};

export const answerStatusStoreDefault = {
  state: undefined,
  text: "",
}

const answerStatusStore = atom<AnswerStatusStoreType>({
	key: "answerStatusStore",
	default: answerStatusStoreDefault,
});

export default answerStatusStore;
