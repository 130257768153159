import { useRecoilValue } from "recoil";
import { activitySelector } from "../../store/eventState";
import Circle from "../../shared/Circle";
import Chip from "../../shared/Chip";

const ActivityLabel = () => {
	const exercise = useRecoilValue(activitySelector);

	if (!exercise) {
		return null;
	}

	return (
		<Chip className="self-stretch">
			<Circle
				className={`flex items-center justify-center self-stretch text-white h-full ${
					exercise.color
				} ${exercise.bg} !aspect-square !rounded h-9 w-9`}
				style={{ stroke: "white !important" }}
			>
				<exercise.icon />
			</Circle>
		</Chip>
	);
};

export default ActivityLabel;
