import { useTranslation } from "react-i18next";
import StarsIcon from "../../icons/StarsIcon";
import useReFetcher from "../../hooks/reFetcher/useRefetcher";

const EventWaiting = () => {
	const { t } = useTranslation();
	useReFetcher();

	return (
		<div
			className="h-dvh w-full p-4 flex items-center justify-center flex-col gap-8"
			style={{
				backgroundImage: "url('https://bayer-be.axensodev.com/images/bg.png')",
				backgroundAttachment: "fixed",
				backgroundPosition: "center",
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover"
			}}
		>
			<div className="flex md:w-[620px] flex-col items-center gap-10 bg-theme-blue p-8 rounded-5">
				<StarsIcon />
				<div className="flex flex-col items-center gap-5 text-center text-white">
					<p className="text-2xl leading-7">{t("Well done")}</p>
					<p className="text-[20px] leading-6 md:text-[24px] md:leading-7">
						{t(
							"You are registered correctly. As soon as a new game is about to start you will be notified with a text message."
						)}
					</p>
				</div>
			</div>
		</div>
	);
};

export default EventWaiting;

/* 
	<p className="text-[24px]">{t("Event is going to start")}</p>
	<h3 className="text-[30px] leading-[36px] md:text-[42px] md:leading-[50.4px] font-bold">
		{t("Emerging technologies in healthcare")}
	</h3> 
*/
