import { Button } from "@chakra-ui/react";
import { BsBroadcastPin } from "react-icons/bs";
import { useRecoilValue } from "recoil";
import eventState from "../../../store/eventState";

const EventStatus = () => {
	const state = useRecoilValue(eventState);
	return (
		<Button
			variant={"primary"}
			className={`${
				state.live ? "green-btn" : "violet-btn"
			} self-stretch btn-base h-9 md:h-12 w-9 md:w-12`}
			// leftIcon={}
		>
			{/* {state.live ? "ONAIR" : "OFFLINE"} */}
			<BsBroadcastPin />
		</Button>
	);
};

export default EventStatus;
