import { atom } from "recoil";

export type CurrentlyShowTypes = "question" | "result";

const currentlyShow = atom<CurrentlyShowTypes>({
  key: "currentlyShowKey",
  default: "question"
});


export default currentlyShow;