import { SandClock } from "../../icons/SandClocks";
import { useQuery } from "@tanstack/react-query";
import { getLobbyData } from "../../lib/services/lobby";
import { FaSpinner } from "react-icons/fa";

const Lobby = () => {
	const { isLoading, data } = useQuery({
		queryKey: ["lobby"],
		queryFn: getLobbyData,
	});

	return (
		<div
			className="rounded flex flex-col items-center justify-center flex-1 gap-5 !object-cover !object-center px-5"
			style={{
				backgroundImage: "url('https://bayer-be.axensodev.com/images/bg.png')",
				backgroundAttachment: "fixed",
				backgroundPosition: "center",
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
			}}
		>
			<div className="flex md:w-[620px] w-full flex-col items-center gap-5 bg-theme-blue p-8 rounded-5">
				{isLoading ? (
					<FaSpinner className="text-2xl text-white animate-spin duration-300" />
				) : (
					<>
						{data?.data.data.image ? (
							<img src={data.data.data.image} alt={data.data.data.title} className="w-full" />
						) : (
							<SandClock color="white" />
						)}
						<h4 className="text-4xl text-white font-bold text-center">
							{data?.data.data.title}
						</h4>
						<p className="text-white text-xl text-center">
							{data?.data.data.description}
						</p>
					</>
				)}
			</div>
		</div>
	);
};

export default Lobby;
