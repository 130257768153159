const V1 = {
	GET_AVATARS: "/v1/avatars",
	SET_AVATAR: "/v1/avatars",

	JOIN_EVENT: "/v1/users/join",

	GET_CONTENT_DETAILS: (id: string) => `/v1/content/${id}/details`,

	REACT_TO_SLIDE: (id: string) => `/v1/slides/${id}/like`,

	SUBMIT_ANSWER: (id: number) => `/v1/surveys/${id}/answer`,

	SUBMIT_LIST_ANSWER: (id: number) => `/v1/lists/${id}/answer`,

	GET_ALL_USERS: `/v1/events/1/users`,

	SUBMIT_QUIZ_ANSWER: (id: number) => `/v1/tf/${id}/answer`,

	SUBMIT_BUZZER_ANSWER: (id: string) => `/v1/buzzers/${id}/answer`,

	IS_ONLINE: "/v1/presenter/1/is-online",

	SUBMIT_GAME_ANSWER: (id: string) => `/v1/games/${id}/answer`,

	GET_PROFESSIONS: "/v1/users/professions",

	REFETCH: (id: number | string) => `/v1/presenter/refresh/${id}`,

	IS_LAST_CONTENT: `/v1/events/1/last`,

	GET_LOBBY: `/v1/events/lobby`,

	DO_USER_EXISTS: (id: string | number) => `/v1/users/${id}`,
} as const;

const END_POINT = {
	V1,
} as const;

export default END_POINT;

// quiz / survey
