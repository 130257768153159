import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_AXIOS_BASE_URL || "",
  headers: {
    "Content-Type": "application/json",
    timeout: 6000,
  },
});


// instance.interceptors.request.use(config => {
//   const authToken = localStorage.getItem('token');

//   if (authToken) {
//     config.headers.Authorization = `Bearer ${authToken}`;
//   }

//   return config;
// });

instance.interceptors.response.use(
  response => response,
  error => {
    // const status = error.response ? error.response.status : null;

    console.log("error");

    // if (status === 401) {
    //   // Handle unauthorized access
    //   localStorage.clear();
    //   window.location.href = "/login"
    // }

    return Promise.reject(error);
  }
);

export default instance;