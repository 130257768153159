import { atom } from "recoil";

export type ResultsStoreTypes = {
  liked: {
    id: number;
    total: number;
    title: string;
    slide_image: string;
  }[];
  disliked: {
    id: number;
    total: number;
    title: string;
    slide_image: string;
  }[];
};

const defaultResults: ResultsStoreTypes = {
  disliked: [],
  liked: [],
};

const resultsStore = atom<ResultsStoreTypes>({
  key: "resultsStore",
  default: defaultResults,
});

export default resultsStore;
