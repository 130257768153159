import { atom } from "recoil";

export type ListStoreTypes = {
	id: string;
	text: string;
	dirty: boolean;
	score: number;
}[];

export const defaultAtomData: ListStoreTypes = [];

const listStore = atom({
	key: "listStore",
	default: defaultAtomData,
});

export default listStore;
