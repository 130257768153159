import { Button } from "@chakra-ui/react";
import Chip from "../../../shared/Chip";
import { useTranslation } from "react-i18next";
import { Checkbox } from "@chakra-ui/react";

type Props =
	| {
			selectable: true;
			onClick: () => void;
			option: string;
			isSelected: boolean;
	  }
	| {
			selectable: false;
			option: string;
			percentage: number;
			maxed: boolean;
	  };

const Option = (props: Props) => {
	const { t } = useTranslation();

	return (
		<Chip
			className={`${
				!props.selectable
					? props.maxed
						? "bg-violet-600"
						: "bg-white"
					: "bg-violet-100 sm:bg-white"
			} flex sm:flex-col items-center justify-between !p-2 sm:!p-4 sm:!min-h-[200px] self-stretch`}
		>
			<h4
				className={`${
					!props.selectable
						? props.maxed
							? "text-white"
							: "text-dolphin-700"
						: "text-dolphin-700"
				} text-[20px] leading-[24px] w-full hidden sm:block`}
			>
				{props.option}
			</h4>
			{props.selectable ? (
				<>
					<Button
						onClick={props.onClick}
						variant={"solid"}
						size={"md"}
						className={`!hidden sm:!block violet-btn btn-base w-full ${
							props.isSelected ? "bg-violet-600" : "!bg-violet-500"
						}`}
					>
						{props.isSelected ? t("Selected") : t("Select")}
					</Button>
					<Checkbox
						variant={"custom"}
						className="flex sm:!hidden"
						onChange={props.onClick}
						isChecked={props.isSelected}
					>
						{props.option}
					</Checkbox>
				</>
			) : (
				<Chip
					className={`${
						props.maxed
							? "bg-white text-violet-600"
							: "bg-violet-600 text-white"
					} w-full py-[13px] text-center font-bold text-[24px] leading-6`}
				>
					{props.percentage}%
				</Chip>
			)}
		</Chip>
	);
};

export default Option;
