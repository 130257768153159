import React from "react";

type Props = {
	title: string;
	description: string;
	currentSlide: number;
	totalSlides: number;
};

const QuestionHeader = ({
	title,
	description,
	currentSlide,
	totalSlides,
}: Props) => {
	return (
		<div className="p-4 bg-violet-500 rounded flex flex-col gap-2 sm:gap-4 sm:bg-transparent">
			<div className="rounded-[2px] py-1 px-2 bg-violet-400 text-white text-xs sm:text-sm w-fit">
				{currentSlide}/{totalSlides}
			</div>
			<div className="flex flex-col gap-[10px] text-white">
				<h4 className="text-xl font-bold leading-6 sm:text-dolphin-700">{title}</h4>
				<p
					className="text-xs leading-5 text-white sm:text-dark-light"
					dangerouslySetInnerHTML={{ __html: description }}
				/>
			</div>
		</div>
	);
};

export default QuestionHeader;
