import React from "react";

const List = () => {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="*"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="16" height="2.04404" rx="1.02202" />
			<rect y="13.9561" width="16" height="2.04404" rx="1.02202" />
			<rect y="6.97787" width="16" height="2.04404" rx="1.02202" />
		</svg>
	);
};

export default List;
