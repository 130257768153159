import { Outlet } from "react-router";
import Header from "./Header/Header";
import useDataStandby from "../hooks/events/useDataStandby";
import useReFetcher from "../hooks/reFetcher/useRefetcher";

const Layout = () => {
	useReFetcher()
	useDataStandby();
	
	return (
		<div className="flex flex-col min-h-dvh">
			<Header />
			<div className="flex-1 h-full flex flex-col">
				<Outlet />
			</div>
		</div>
	);
};

export default Layout;
