import END_POINT from "../api/END_POINTS";
import axios from "../clients/axiosInstance";

export const submitAnswer = (
  event_id: number,
  user_id: number,
  question_id: number,
  answer: 0 | 1
) => {
  return axios.post(END_POINT.V1.SUBMIT_QUIZ_ANSWER(event_id), {
    user_id,
    question_id,
    answer,
  });
};
