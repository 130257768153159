const Quiz = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="*" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.16656 11.6668C4.6268 11.6668 4.9999 11.2937 4.9999 10.8334C4.9999 10.3732 4.6268 10.0001 4.16656 10.0001C3.70633 10.0001 3.33323 10.3732 3.33323 10.8334C3.33323 11.2937 3.70633 11.6668 4.16656 11.6668Z" />
      <path d="M0.833339 8.33351C1.29358 8.33351 1.66668 7.96041 1.66668 7.50017C1.66668 7.03993 1.29358 6.66684 0.833339 6.66684C0.373098 6.66684 0 7.03993 0 7.50017C0 7.96041 0.373098 8.33351 0.833339 8.33351Z" />
      <path d="M4.16656 4.9999C4.6268 4.9999 4.9999 4.6268 4.9999 4.16656C4.9999 3.70633 4.6268 3.33323 4.16656 3.33323C3.70633 3.33323 3.33323 3.70633 3.33323 4.16656C3.33323 4.6268 3.70633 4.9999 4.16656 4.9999Z" />
      <path d="M0.833345 1.66668C1.29359 1.66668 1.66668 1.29359 1.66668 0.833345C1.66668 0.373105 1.29359 6.35787e-06 0.833345 6.35787e-06C0.373105 6.35787e-06 6.35787e-06 0.373105 6.35787e-06 0.833345C6.35787e-06 1.29359 0.373105 1.66668 0.833345 1.66668Z" />
      <path d="M19.1667 11.6668C19.6269 11.6668 20 11.2937 20 10.8334C20 10.3732 19.6269 10.0001 19.1667 10.0001C18.7064 10.0001 18.3333 10.3732 18.3333 10.8334C18.3333 11.2937 18.7064 11.6668 19.1667 11.6668Z" />
      <path d="M15.8334 8.33351C16.2937 8.33351 16.6668 7.96041 16.6668 7.50017C16.6668 7.03993 16.2937 6.66684 15.8334 6.66684C15.3732 6.66684 15.0001 7.03993 15.0001 7.50017C15.0001 7.96041 15.3732 8.33351 15.8334 8.33351Z" />
      <path d="M19.1667 4.9999C19.6269 4.9999 20 4.6268 20 4.16656C20 3.70633 19.6269 3.33323 19.1667 3.33323C18.7064 3.33323 18.3333 3.70633 18.3333 4.16656C18.3333 4.6268 18.7064 4.9999 19.1667 4.9999Z" />
      <path d="M15.8334 1.66668C16.2937 1.66668 16.6668 1.29358 16.6668 0.833339C16.6668 0.373098 16.2937 0 15.8334 0C15.3732 0 15.0001 0.373098 15.0001 0.833339C15.0001 1.29358 15.3732 1.66668 15.8334 1.66668Z" />
      <path d="M11.6666 20C12.1269 20 12.5 19.6269 12.5 19.1667C12.5 18.7064 12.1269 18.3333 11.6666 18.3333C11.2064 18.3333 10.8333 18.7064 10.8333 19.1667C10.8333 19.6269 11.2064 20 11.6666 20Z" />
      <path d="M8.33339 16.6668C8.79363 16.6668 9.16673 16.2937 9.16673 15.8334C9.16673 15.3732 8.79363 15.0001 8.33339 15.0001C7.87315 15.0001 7.50005 15.3732 7.50005 15.8334C7.50005 16.2937 7.87315 16.6668 8.33339 16.6668Z" />
      <path d="M11.6666 13.3336C12.1269 13.3336 12.5 12.9605 12.5 12.5002C12.5 12.04 12.1269 11.6669 11.6666 11.6669C11.2064 11.6669 10.8333 12.04 10.8333 12.5002C10.8333 12.9605 11.2064 13.3336 11.6666 13.3336Z" />
      <path d="M8.33339 9.99995C8.79363 9.99995 9.16673 9.62685 9.16673 9.1666C9.16673 8.70636 8.79363 8.33326 8.33339 8.33326C7.87315 8.33326 7.50005 8.70636 7.50005 9.1666C7.50005 9.62685 7.87315 9.99995 8.33339 9.99995Z" />
      <path d="M12.5002 2.50002C12.0582 2.50002 11.6342 2.32442 11.3217 2.01186C11.0091 1.69929 10.8335 1.27537 10.8335 0.833339C10.8335 0.612323 10.7457 0.400361 10.5894 0.244079C10.4332 0.0877979 10.2212 0 10.0002 0C9.77917 0 9.56721 0.0877979 9.41093 0.244079C9.25464 0.400361 9.16685 0.612323 9.16685 0.833339C9.16685 1.27537 8.99125 1.69929 8.67869 2.01186C8.36613 2.32442 7.9422 2.50002 7.50017 2.50002C7.27916 2.50002 7.0672 2.58781 6.91091 2.7441C6.75463 2.90038 6.66684 3.11234 6.66684 3.33335C6.66684 3.55437 6.75463 3.76633 6.91091 3.92261C7.0672 4.0789 7.27916 4.16669 7.50017 4.16669C7.9422 4.16669 8.36613 4.34229 8.67869 4.65485C8.99125 4.96741 9.16685 5.39134 9.16685 5.83337C9.16685 6.05439 9.25464 6.26635 9.41093 6.42263C9.56721 6.57891 9.77917 6.66671 10.0002 6.66671C10.2212 6.66671 10.4332 6.57891 10.5894 6.42263C10.7457 6.26635 10.8335 6.05439 10.8335 5.83337C10.8335 5.39134 11.0091 4.96741 11.3217 4.65485C11.6342 4.34229 12.0582 4.16669 12.5002 4.16669C12.7212 4.16669 12.9332 4.0789 13.0895 3.92261C13.2457 3.76633 13.3335 3.55437 13.3335 3.33335C13.3335 3.11234 13.2457 2.90038 13.0895 2.7441C12.9332 2.58781 12.7212 2.50002 12.5002 2.50002Z" />
      <path d="M5.83337 15.8333C5.39134 15.8333 4.96741 15.6577 4.65485 15.3452C4.34229 15.0326 4.16669 14.6087 4.16669 14.1666C4.16669 13.9456 4.0789 13.7337 3.92261 13.5774C3.76633 13.4211 3.55437 13.3333 3.33335 13.3333C3.11234 13.3333 2.90038 13.4211 2.7441 13.5774C2.58781 13.7337 2.50002 13.9456 2.50002 14.1666C2.50002 14.6087 2.32442 15.0326 2.01186 15.3452C1.69929 15.6577 1.27537 15.8333 0.833339 15.8333C0.612323 15.8333 0.400361 15.9211 0.244079 16.0774C0.0877979 16.2337 0 16.4456 0 16.6667C0 16.8877 0.0877979 17.0996 0.244079 17.2559C0.400361 17.4122 0.612323 17.5 0.833339 17.5C1.27537 17.5 1.69929 17.6756 2.01186 17.9881C2.32442 18.3007 2.50002 18.7246 2.50002 19.1667C2.50002 19.3877 2.58781 19.5996 2.7441 19.7559C2.90038 19.9122 3.11234 20 3.33335 20C3.55437 20 3.76633 19.9122 3.92261 19.7559C4.0789 19.5996 4.16669 19.3877 4.16669 19.1667C4.16669 18.7246 4.34229 18.3007 4.65485 17.9881C4.96741 17.6756 5.39134 17.5 5.83337 17.5C6.05439 17.5 6.26635 17.4122 6.42263 17.2559C6.57891 17.0996 6.66671 16.8877 6.66671 16.6667C6.66671 16.4456 6.57891 16.2337 6.42263 16.0774C6.26635 15.9211 6.05439 15.8333 5.83337 15.8333Z" />
      <path d="M19.1667 15.8333C18.7246 15.8333 18.3007 15.6577 17.9881 15.3452C17.6756 15.0326 17.5 14.6087 17.5 14.1666C17.5 13.9456 17.4122 13.7337 17.2559 13.5774C17.0996 13.4211 16.8877 13.3333 16.6667 13.3333C16.4456 13.3333 16.2337 13.4211 16.0774 13.5774C15.9211 13.7337 15.8333 13.9456 15.8333 14.1666C15.8333 14.6087 15.6577 15.0326 15.3452 15.3452C15.0326 15.6577 14.6087 15.8333 14.1666 15.8333C13.9456 15.8333 13.7337 15.9211 13.5774 16.0774C13.4211 16.2337 13.3333 16.4456 13.3333 16.6667C13.3333 16.8877 13.4211 17.0996 13.5774 17.2559C13.7337 17.4122 13.9456 17.5 14.1666 17.5C14.6087 17.5 15.0326 17.6756 15.3452 17.9881C15.6577 18.3007 15.8333 18.7246 15.8333 19.1667C15.8333 19.3877 15.9211 19.5996 16.0774 19.7559C16.2337 19.9122 16.4456 20 16.6667 20C16.8877 20 17.0996 19.9122 17.2559 19.7559C17.4122 19.5996 17.5 19.3877 17.5 19.1667C17.5 18.7246 17.6756 18.3007 17.9881 17.9881C18.3007 17.6756 18.7246 17.5 19.1667 17.5C19.3877 17.5 19.5996 17.4122 19.7559 17.2559C19.9122 17.0996 20 16.8877 20 16.6667C20 16.4456 19.9122 16.2337 19.7559 16.0774C19.5996 15.9211 19.3877 15.8333 19.1667 15.8333Z" />
    </svg>
  );
};

export default Quiz;
