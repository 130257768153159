import { useRecoilValue } from "recoil";
import Chip from "../../shared/Chip";
import Circle from "../../shared/Circle";
import eventState, { activitySelector } from "../../store/eventState";

const Title = () => {
	const exercise = useRecoilValue(activitySelector);
	const state = useRecoilValue(eventState);

	return (
		<>
			{exercise ? (
				<Chip className="flex flex-1 items-center gap-5 self-stretch px-4 text-[15px] font-bold text-dolphin-700">
					<Circle
						className={`flex items-center justify-center self-stretch text-white ${
							exercise.color
						} ${exercise.bg} ${
							state.live ? "" : "!aspect-auto gap-[14px] px-[18px]"
						} min-w-[50px] w-[50px] max-w-[50px] min-h-[50px] h-[50px] max-h-[50px] !rounded`}
						style={{ stroke: "white !important" }}
					>
						<exercise.icon />
					</Circle>
					<span className="line-clamp-1">
						{state.activity ? state.activityTitle : state.eventTitle}
					</span>
				</Chip>
			) : (
				<Chip className="flex flex-1 items-center gap-5 self-stretch px-4 text-[15px] font-bold text-dolphin-700">
					{state.activity ? state.activityTitle : state.eventTitle}
				</Chip>
			)}
		</>
	);
};

export default Title;
