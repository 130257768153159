import { selectAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  selectAnatomy.keys
);

const select = definePartsStyle({
  field: {
    fontFamily: "Inter",
    fontSize: "18px",
    color: "black",
    py: "20px",
    textAlign: "center",
    outline: "none",
    borderRadius: 5,
    width: "100%",
  },
  icon: {}
});

export const selectTheme = defineMultiStyleConfig({
  variants: {
    select,
  },
});
