import userStore from "../../../store/userStore";
import { Button, useToast } from "@chakra-ui/react";
import AvatarSelect from "./AvatarSelect";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { setAvatar } from "../../../lib/services/users";
import { useState } from "react";
import { FaSpinner } from "react-icons/fa";
import Logo from "../../../icons/Logo";

const Welcome = () => {
	const [userInfo, setUserInfo] = useRecoilState(userStore);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [selectedImage, setSelectedImage] = useState("");

	const toast = useToast();

	const { mutateAsync } = useMutation({
		mutationFn: () => {
			return setAvatar(selectedImage, userInfo.user_id);
		},
		onSuccess: () => {
			setUserInfo((prev) => ({ ...prev, imgUrl: selectedImage }));
		},
	});

	const { t } = useTranslation();

	return (
		<div
			className="flex flex-col h-dvh items-center justify-center gap-8 !p-4"
			style={{
				backgroundImage: "url('https://bayer-be.axensodev.com/images/bg.png')",
				backgroundAttachment: "fixed",
				backgroundPosition: "center",
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
			}}
		>
			<div className="flex flex-col items-center justify-center gap-[30px]  p-8 bg-theme-blue text-white rounded-5 w-full max-w-[420px]">
				<div className="flex flex-col items-center gap-[10px] text-white">
					<p className="text-2xl md:text-[30px]">{t("Welcome")}</p>
					<p className="font-bold text-[30px]">{`${userInfo.firstName} ${userInfo.lastName}`}</p>
				</div>
				<AvatarSelect
					setSelectedImage={setSelectedImage}
					selectedImage={selectedImage}
				/>
				<p className="text-base leading-[22px]">{t("Choose your avatar")}</p>
				<Button
					onClick={() => {
						setLoading(true);
						mutateAsync()
							.then(() => {
								navigate("/thanks");
							})
							.catch(() => {
								toast({
									title: t("Something went wrong") + ".",
									description: t("Please try again") + ".",
									status: "error",
									duration: 9000,
									isClosable: true,
								});
							})
							.finally(() => setLoading(false));
					}}
					isDisabled={
						typeof userInfo.imgUrl === "string" && userInfo.imgUrl.length < 4
					}
					variant={"primary"}
					size="md"
					isLoading={loading}
					spinner={
						<FaSpinner size={20} color="white" className="animate-spin" />
					}
					className="orange-btn w-full !py-4 max-w-[420px]"
				>
					{t("Start")}
				</Button>
				{/* <Link
						className="text-lg leading-[21.6px]"
						to="../select-proficiency"
					>
						{t("back")}
					</Link> */}
			</div>
			<Logo />
		</div>
	);
};

export default Welcome;
