import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import userSubmission from "./store";
import surveyState from "../store";
import Option from "../Option";
import "swiper/css";
import QuestionHeader from "./QuestionHeader";

const Questions = () => {
	const swiperRef = useRef<SwiperClass | null>(null);
	const [submissionData, setSubmissionData] = useRecoilState(userSubmission);
	const [surveyData] = useRecoilState(surveyState);

	const updateSubmission = (qId: string, opId: string) => {
		setSubmissionData((prev) => {
			return {
				...prev,
				userSelected: prev.userSelected.map((question, i) => {
					if (submissionData.currentIndex === i) {
						const optionSelected = question.selected.includes(+opId);
						const { type_name } =
							surveyData.questions[submissionData.currentIndex];

						if (type_name === "Multiple") {
							if (optionSelected) {
								return {
									id: qId,
									selected: question.selected.filter((item) => item !== +opId),
								};
							} else {
								return {
									id: qId,
									selected: [...question.selected, +opId],
								};
							}
						} else {
							if (optionSelected) {
								return {
									id: qId,
									selected: [],
								};
							} else {
								return {
									id: qId,
									selected: [+opId],
								};
							}
						}
					}

					return {
						...question,
					};
				}),
			};
		});
	};

	useEffect(() => {
		if (swiperRef.current) {
			swiperRef?.current?.slideTo(surveyData.currentIndex);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [surveyData.currentIndex]);

	return (
		<div className="flex-1">
			<Swiper
				onSwiper={(swiper: SwiperClass) => {
					swiperRef.current = swiper;
				}}
				spaceBetween={50}
				slidesPerView={1}
				autoplay={false}
				draggable={false}
				allowTouchMove={false}
				className="!h-full"
			>
				{surveyData.questions.map((sur, j) => (
					<SwiperSlide
						key={sur.id}
						className="!h-[calc(100dvh-132px)] md:!h-[calc(100dvh-106px-96px)] mt-2 sm:bg-violet-100"
					>
						<div className="flex flex-col gap-2 h-full">
							<QuestionHeader
								currentSlide={j + 1}
								totalSlides={surveyData.total}
								description={sur.description}
								title={sur.question}
							/>
							<div className="flex flex-col sm:grid sm:grid-cols-12 gap-5 overflow-y-scroll custom-scroll flex-1 !h-full sm:px-4">
								{sur.options.map((op, i) => {
									const selected = submissionData.userSelected[
										surveyData.currentIndex
									].selected.includes(+op.id);
									return (
										<div
											key={i}
											className="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3 self-stretch h-fit row-span-1"
										>
											<Option
												isSelected={selected ? selected : false}
												key={i}
												selectable={true}
												onClick={() => updateSubmission(sur.id, op.id)}
												{...op}
											/>
										</div>
									);
								})}
							</div>
						</div>
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
};

export default Questions;
