import { useRecoilValue } from "recoil";
import Chip from "../../shared/Chip";
import surveyState from "../../pages/activities/survey/store";

const SurveyCounter = () => {
	const state = useRecoilValue(surveyState);

	return (
		<Chip className="flex items-center gap-4 self-stretch p-2">
			<Chip className="flex items-center self-stretch bg-dark-extra px-9 text-lg font-bold text-dolphin-700">
				{state.currentIndex + 1}/{state.total}
			</Chip>
		</Chip>
	);
};

export default SurveyCounter;
