import { atom } from "recoil";

export type UserSubmissionTypes = {
	currentIndex: number;
	total: number;
	userSelected: { id: string; selected: number[] }[];
}

const userSubmission = atom<UserSubmissionTypes>({
	key: "userSubmission",
	default: {
		currentIndex: 0,
		total: 0,
		userSelected: [],
	},
});

export default userSubmission;
