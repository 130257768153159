import { atom } from "recoil";

export type OptionSurveyStoreType = {
	id: string;
	option: string;
	option_percentage: number;
	option_progress: number;
};

export type surveyStoreType = {
	currentIndex: number;
	total: number;
	questions: {
		id: string;
		question: string;
		description: string;
		type_name: "Single" | "Multiple";
		options: OptionSurveyStoreType[];
	}[];
};

export const defaultSurveyState: surveyStoreType = {
	currentIndex: 0,
	total: 0,
	questions: [],
};

const surveyState = atom<surveyStoreType>({
	key: "surveyStore",
	default: defaultSurveyState,
});

export default surveyState;
