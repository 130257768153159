import Questions from "./Questions";
import SurveyControl from "./SurveyControl";

const Survey = () => {


	return (
		<div className="md:px-5 md:pb-5 px-2 pb-2 flex-1 flex flex-col gap-2 md:gap-5 justify-between">
			<Questions />
			<SurveyControl />
		</div>
	);
};

export default Survey;
