import { extendBaseTheme } from "@chakra-ui/react";
import { buttonTheme } from "./button";
import { inputTheme } from "./input";
import { theme as th } from "@chakra-ui/theme";
import { info, popup } from "./modal";
import { selectTheme } from "./Select";
import { checkboxTheme } from "./checkbox/checkbox";

// ?? Not documented in chakra's website nether their github page
const { Modal, Alert, Checkbox } = th.components;
// ! ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

const theme = extendBaseTheme({
  components: {
    Button: buttonTheme,
    Input: inputTheme,
    Modal: {
      ...Modal,
      variants: {
        ...Modal.variants,
        popup,
        info,
      },
    },
    Alert,
    Select: selectTheme,
    Checkbox: {
      ...Checkbox,
      ...checkboxTheme
    }
  },
});

export default theme;
