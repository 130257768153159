import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import Question from "./Question";
import userAnswers from "../store";
import { useRecoilState } from "recoil";
import { useEffect, useRef } from "react";
import currentlyShow from "./currentlyShow.store";
import answerStatusStore from "./answerStatus.store";
import Result from "./Result";

type Props = {
	current: number;
	description: string;
	text: string;
};

const QuestionAnswer = ({ current, description, text }: Props) => {
	const [submissionData, setSubmissionData] = useRecoilState(userAnswers);
	const swiperRef = useRef<SwiperClass | null>(null);
	const [show, setShow] = useRecoilState(currentlyShow);
	const [answer, setAnswerStatus] = useRecoilState(answerStatusStore);

	useEffect(() => {
		setAnswerStatus({ state: undefined, text: "" });
		swiperRef.current?.slideTo(0);
		setShow("question");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (show === "result" && answer !== undefined) {
			swiperRef.current?.slideTo(1);
		} else if (show === "question") {
			swiperRef.current?.slideTo(0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [show]);

	const handleClick = (state: 1 | -1) => {
		setSubmissionData((prev) => ({
			...prev,
			answers: prev.answers.map((ans, i) =>
				i === prev.currentIndex ? { ...ans, userSelect: state } : ans
			),
		}));
	};

	return (
		<Swiper
			onSwiper={(swiper: SwiperClass) => {
				swiperRef.current = swiper;
			}}
			spaceBetween={50}
			slidesPerView={1}
			autoplay={false}
			draggable={false}
			allowTouchMove={false}
		>
			<SwiperSlide
				key={`${
					submissionData.answers[submissionData.currentIndex].id
				}-Question`}
				className="h-full"
			>
				<Question
					activeFalse={
						submissionData.answers[submissionData.currentIndex].userSelect ===
						-1
					}
					activeTrue={
						submissionData.answers[submissionData.currentIndex].userSelect === 1
					}
					description={description}
					handleClickTrue={() => handleClick(1)}
					handleClickFalse={() => handleClick(-1)}
					total={submissionData.total}
					current={current}
					text={text}
				/>
			</SwiperSlide>
			<SwiperSlide
				key={`${submissionData.answers[submissionData.currentIndex].id}-Answer`}
				className="h-full "
			>
				<Result answer={answer.state} message={answer.text} />
			</SwiperSlide>
		</Swiper>
	);
};

export default QuestionAnswer;
