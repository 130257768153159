import Pusher from "pusher-js";

const pusherInstance = (userId: string, user_profile: string) => {
	return new Pusher(process.env.REACT_APP_PUSHER_APP_KEY as string, {
		cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER as string,
		authEndpoint: `${process.env.REACT_APP_AXIOS_BASE_URL}/v1/pusherAuth`,
		auth: {
			params: {
				userType: "user",
				user_id: userId,
				img: user_profile,
			},
		},
		forceTLS: true,
	});
};

export default pusherInstance;
