import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import "swiper/css";
import userAnswers from "../store";
import QuestionAnswer from "./QuestionAnswer";

// ! bg-green-600
// ! bg-orange-600

const Questions = () => {
	const swiperRef = useRef<SwiperClass | null>(null);
	const [submissionData] = useRecoilState(userAnswers);

	useEffect(() => {
		if (swiperRef.current) {
			swiperRef?.current?.slideTo(submissionData.currentIndex);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [submissionData.currentIndex]);

	// const handleClick = (state: 1 | -1) => {
	// 	setSubmissionData((prev) => ({
	// 		...prev,
	// 		answers: prev.answers.map((ans, i) =>
	// 			i === prev.currentIndex ? { ...ans, userSelect: state } : ans
	// 		),
	// 	}));
	// };

	return (
		<div className="flex-1 h-full bg-dolphin-100 !rounded flex">
			<Swiper
				onSwiper={(swiper: SwiperClass) => {
					swiperRef.current = swiper;
				}}
				spaceBetween={50}
				slidesPerView={1}
				autoplay={false}
				draggable={false}
				allowTouchMove={false}
			>
				{submissionData.data.map((sur, j) => (
					<SwiperSlide key={sur.id} className="h-full">
						<QuestionAnswer
							current={j + 1}
							description={sur.description}
							text={sur.text}
						/>
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
};

export default Questions;
