import Controls from "./Controls";
import Questions from "./Questions";

const Quiz = () => {


	return (
		<div className="px-2 pb-2 md:px-5 md:pb-5 flex-1 flex flex-col gap-5 justify-between">
			<Questions />
			<Controls />
		</div>
	);
};

export default Quiz;
