/* eslint-disable react-hooks/exhaustive-deps */
// import { useState } from "react";
import { useRecoilState } from "recoil";
import eventState from "../../store/eventState";
import { useNavigate } from "react-router-dom";
import Pusher from "pusher-js/types/src/core/pusher";
import listStore from "../../pages/activities/list/newDNDActivity/store.atom";
import listScore from "../../pages/activities/list/newDNDActivity/score.atom";
import currentlyShow, {
	CurrentlyShowTypes,
} from "../../pages/activities/list/current.store";
import currentlySort, {
	CurrentlySortType,
} from "../../pages/activities/list/currentlySort";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export type Actions =
	| "start"
	| "show"
	| "end"
	| "show_results"
	| "prev"
	| "next"
	| "show_Dermatologists_score"
	| "show_Dermatologists_scale"
	| "show_Reumatologists_score"
	| "show_Reumatologists_scale"
	| "show_all_score"
	| "show_all_scale";

type normalMsg = {
	content_id: number;
	content_type: number;
	content_type_name: string;
	action: Actions;
	showable_content: {
		id: number;
		title: string;
		description: string;
		type: number;
		type_name: "Make-Your-List";
		// overall_progress: string;
		// options: {
		// 	id: number;
		// 	text: string;
		// }[];
	};
	question_id: null;
	results: {
		overall_progress: number;
		score: {
			option_id: number;
			text: string;
			score: { all: number; Reumatologists: number; Dermatologists: number };
			scale: { all: number; Reumatologists: number; Dermatologists: number };
		}[];
	};
};

type scoreMsg = {
	action: "user-submit";
	overall_progress: string;
	score: {
		option_id: number;
		text: string;
		score: { all: number; Reumatologists: number; Dermatologists: number };
		scale: { all: number; Reumatologists: number; Dermatologists: number };
	}[];
	content_type_name: "Make-Your-List";
	content_type: 5;
	content_id: number;
	title: string;
	description: string;
};

type MsgType = {
	data: normalMsg | scoreMsg;
	eventType: string;
};

const useListChannel = () => {
	const navigate = useNavigate();
	const toast = useToast();
	const { t } = useTranslation();

	const [, setState] = useRecoilState(eventState);
	const [, setListState] = useRecoilState(listStore);
	const [, setListScore] = useRecoilState(listScore);
	const [, setCurrentlyShow] = useRecoilState(currentlyShow);
	const [, setCurrentlySort] = useRecoilState(currentlySort);
	// const [count, setCount] = useState(1);

	const channel_name = "private-presenter";

	const handleStartEvent = (data: MsgType) => {
		if (data.data.action !== "user-submit") {
			const cData = data.data as normalMsg;
			setState((prev) => ({
				...prev,
				activity: {
					activityName: cData.content_type_name,
					activityType: cData.content_type,
				},
				activeContentId: cData.content_id,
				activityName: cData.content_type_name,
				activityTitle: cData.showable_content.title,
				description: cData.showable_content.description,
				showHeaderControl: true,
			}));
			setListState(
				data.data.results.score.map((item) => ({
					...item,
					id: item.option_id.toString(),
					dirty: false,
					score: 1,
				}))
			);
		}
	};

	const handleInitList = (pusher: Pusher) => {
		const listChannel = pusher.subscribe(channel_name);

		// console.log("this must appear once list channel", count);
		// setCount((prev) => prev + 1);

		listChannel.bind("make-your-list", (data: MsgType) => {
			switch (data.data.action) {
				case "start":
					handleStartEvent(data);
					navigate(
						`/event/activity/${data.data.content_type}/list/make-your-list`
					);
					break;
				case "end":
					// set results here
					navigate(
						`/event/activity/${data.data.content_type}/list/wait-results`
					);
					break;
				case "show_results":
					const sData = data.data as normalMsg;

					setListScore(sData.results);
					navigate(
						`/event/activity/${data.data.content_type}/list/show-result`
					);
					break;
				// case "show_Dermatologists":
				// 	setCurrentlyShow("Dermatologists");
				// 	break;
				// case "show_Reumatologists":
				// 	setCurrentlyShow("Reumatologists");
				// 	break;
				// case "show_all":
				// 	setCurrentlyShow("all");
				// 	break;
				// case "user-submit":
				// if (
				// 	!(
				// 		window.location.pathname.includes("make-your-list") ||
				// 		window.location.pathname.includes("wait-results") ||
				// 		window.location.pathname.includes("show-result")
				// 	)
				// ) {
				// 	const cData = data.data as scoreMsg;
				// 	setListState(
				// 		cData.score.map((item) => ({
				// 			...item,
				// 			id: item.option_id.toString(),
				// 			dirty: false,
				// 			score: 1,
				// 		}))
				// 	);
				// 	setState((prev) => ({
				// 		...prev,
				// 		activity: {
				// 			activityName: cData.content_type_name,
				// 			activityType: cData.content_type,
				// 		},
				// 		activeContentId: cData.content_id,
				// 		activityName: cData.content_type_name,
				// 		activityTitle: cData.title,
				// 		description: cData.description,
				// 		showHeaderControl: true,
				// 	}));
				// 	navigate(`/event/activity/5/list/make-your-list`);
				// }
				// break;
				default:
					const action = data.data.action;
					const restActions = [
						"show_Dermatologists_score",
						"show_Dermatologists_scale",
						"show_Reumatologists_score",
						"show_Reumatologists_scale",
						"show_all_score",
						"show_all_scale",
					];
					if (restActions.some((a) => a === action)) {
						const actions = action.split("_");
						setCurrentlyShow(actions[1] as CurrentlyShowTypes);
						setCurrentlySort(actions[2] as CurrentlySortType);
						toast({
							description: `${t("Showing results sorted by")} ${t(actions[2])}`,
						});
					}
					break;
			}
		});

		// listChannel.bind(
		// 	"content-end",
		// 	(_: { data: []; eventType: "content-end" }) => {
		// 		setState((prev) => ({
		// 			...prev,
		// 			activeContentId: -1,
		// 			activity: undefined,
		// 			showHeaderControl: false,
		// 			activityTitle: "",
		// 		}));
		// 		navigate("/event/activity");
		// 	}
		// );
	};

	return {
		handleInitList,
	};
};

export default useListChannel;
