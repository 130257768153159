import Chip from "../../../../shared/Chip";
import QuestionHeader from "../activity/QuestionHeader";
import { OptionSurveyStoreType } from "../store";
import Option from "./Option";

type Props = {
	currentSlide: number;
	totalSlides: number;
	results: number[];
	surveyData: {
		id: string;
		question: string;
		description: string;
		options: OptionSurveyStoreType[];
	};
};

const ResultCard = ({
	currentSlide,
	surveyData,
	totalSlides,
	results,
}: Props) => {
	return (
		<Chip className="rounded !p-0 sm:!bg-dolphin-100 !bg-transparent  flex flex-col gap-2 md:gap-10 w-full h-[calc(100dvh-60px-20px-80px)] md:h-[calc(100dvh-20px-106px-80px)]">
			<QuestionHeader
				currentSlide={currentSlide}
				totalSlides={totalSlides}
				description={surveyData.description}
				title={surveyData.question}
			/>
			<div className="flex flex-col sm:!p-5 sm:!pt-0 gap-5 overflow-y-scroll h-full custom-scroll bg-violet-100 p-2">
				{surveyData.options.map((op, i) => {
					const max = results.reduce((a, b) => Math.max(a, b), -Infinity);
					return (
						<div
							key={i}
							className="col-span-12 sm:col-span-6 lg:col-span-3 2xl:col-span-2 self-stretch row-span-1"
						>
							<Option
								key={i}
								option={op.option}
								percentage={Math.trunc(Number(op.option_progress))}
								mostChose={max ===  op.option_progress}
							/>
						</div>
					);
				})}
			</div>
		</Chip>
	);
};

export default ResultCard;
