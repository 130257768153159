import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import userAnswers from "./store";

const QuizErrorCatcher = () => {
	const navigate = useNavigate();

	const userAnswer = useRecoilValue(userAnswers);

	useEffect(() => {
		if (userAnswer.data.length === 0) {
			return navigate("/event/activity/lobby");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <Outlet />;
};

export default QuizErrorCatcher;
