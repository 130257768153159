import { defineStyle, defineStyleConfig } from "@chakra-ui/react";
import { outline } from "./outline";
import { primary } from "./primary";

export const buttonTheme = defineStyleConfig({
  variants: { outline, primary },
  sizes: {
    xs: defineStyle({
      padding: "11px 16px",
      fontSize: "14px",
      fontWeight: "400",
    }),
    sm: defineStyle({
      padding: "12px 21px",
      fontSize: "16px",
    }),
    md: defineStyle({
      padding: "20px",
      fontSize: "18px",
    }),
    lg: defineStyle({
      padding: "30px 60px",
      fontSize: "24px",
    }),
  },
  baseStyle: {
    borderRadius: "4px",
    cursor: "pointer",
    _disabled: {
      cursor: "not-allowed",
    },
  },
});
