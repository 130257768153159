import { atom, selector } from "recoil";
import { mapExerciseToValue } from "../utils/mapExerciseToValue";
import { mapExerciseToIndicator } from "../utils/mapExerciseToIndicator";


type GeneralResponse = {
  id: number;
  created_at: string;
  updated_at: string;
};

export type Slides = GeneralResponse & {
  description: string;
  image: string;
  sort_order: number;
  status: number;
  title: string;
  content_id: 1;
};

export type Quiz = GeneralResponse & {
  answer: number;
  sort_order: number;
  text: string;
  content_id: 2;
  description: string | null;
};

export type Survey = GeneralResponse & {
  content_id: 3;
  text: string;
  description: string | null;
  sort_order: number;
  options: GeneralResponse &
  {
    question_id: number;
    sort_order: number;
    text: string;
  }[];
};

export type Buzzer = GeneralResponse & {
  content_id: 4;
  text: string;
  sort_order: number;
  description: string;
  id: number;
  buzzer_options: {
    buzzer_question_id: number;
    id: number;
    is_correct: 1 | 0;
    sort_order: number;
    text: string;
  }[]
};

export type MakeYourList = GeneralResponse & {
  content_id: 5;
  sort_order: number;
  text: string;
};

export type SlideActivity = {
  id: number;
  title: string;
  description: string;
  type: 1;
  type_name: "Slideshow";
  slides: Slides[];
};

export type QuizActivity = {
  id: number;
  title: string;
  description: string;
  type: 2;
  type_name: "True/False";
  questions: Quiz[];
};

export type SurveyActivity = {
  id: number;
  title: string;
  description: string;
  type: 3;
  type_name: "Survey";
  questions: Survey[];
};

export type BuzzerActivity = {
  id: number;
  title: string;
  description: string;
  type: 4;
  type_name: "Buzzer";
  questions: Buzzer[];
};

export type MakeYourListActivity = {
  id: number;
  title: string;
  description: string;
  type: 5;
  type_name: "Make Your List";
  options: MakeYourList[];
};

export type activityType =
  | SlideActivity
  | QuizActivity
  | SurveyActivity
  | BuzzerActivity
  | MakeYourListActivity;

export type eventStateType = {
  live: boolean;
  eventTitle: string;
  activityTitle: string;
  showHeaderControl: boolean;
  activeContentId: number;
  description: string;
  activity:
  | {
    activityType: number;
    activityName: string;
  }
  | undefined;
};

export const defaultEventState = {
  live: false,
  activityTitle: "",
  showHeaderControl: false,
  eventTitle: "",
  activity: undefined,
  activities: [],
  activeContentId: -1,
  description: "",
}

const eventState = atom<eventStateType>({
  key: "eventState",
  default: defaultEventState,
});

export const activitySelector = selector({
  key: "activitySelector",
  get: ({ get }) => {
    const eState: eventStateType = get(eventState);
    if (eState.activity) {
      const currentActivity = mapExerciseToValue(eState.activity.activityType);

      return currentActivity;
    }

    return undefined;
  },
});

export const activityIndicatorSelector = selector({
  key: "activityIndicatorSelector",
  get: ({ get }) => {
    const eState: eventStateType = get(eventState);
    if (eState.activity) {
      const currentActivity = mapExerciseToIndicator(eState.activity.activityType);
      console.log(eState.activity);

      return currentActivity;
    }

    return undefined;
  },
});

export default eventState;
