import ResultCard from "./ResultCard";
import Chip from "../../../../shared/Chip";
import { useRecoilValue } from "recoil";
import quizStore from "../store";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const QuizResults = () => {
	const quiz = useRecoilValue(quizStore);
	const { t } = useTranslation();

	const navigate = useNavigate();

	return (
		<div className="p-5 flex flex-col gap-5">
			<Chip className="p-2 md:p-[30px] rounded h-[calc(100dvh-60px-40px)] md:h-[calc(100dvh-106px-40px)] flex flex-col gap-4">
				<div className="grid grid-cols-12 gap-5 overflow-y-scroll custom-scroll h-[calc(100dvh-60px-40px-40px)]">
					{quiz.data.map((q, i) => (
						<div
							key={i}
							className="col-span-12 md:col-span-6 xl:col-span-4 2xl:col-span-3 self-stretch row-span-1"
						>
							<ResultCard
								key={i}
								index={i}
								description={q.description}
								title={q.text}
								falseCount={q.results.false_count}
								trueCount={q.results.true_count}
								isTrue={q.results.correct_answer}
							/>
						</div>
					))}
				</div>
				<div>
					<button
						className={`py-[11px] text-lg leading-[21.8px] text-center font-bold text-white w-full ${"bg-cyan-600 hover:bg-cyan-700"} rounded flex-1`}
						onClick={() => navigate("/event/activity/end")}
					>
						{t("Close")}
					</button>
				</div>
			</Chip>
		</div>
	);
};

export default QuizResults;
