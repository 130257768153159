// import { Button } from "@chakra-ui/react";
import Controls from "./Controls";
import Result from "./Result";

const Results = () => {
	return (
		<div className="px-2 pb-2 md:px-5 md:pb-5 flex-1 flex flex-col gap-5 justify-between">
			<Result />
			<Controls />
		</div>
	);
};

export default Results;
