import { useSpring } from "framer-motion";
import Number from "./Number";
import { useEffect } from "react";

const fontSize = 30;
const padding = 15;
const height = fontSize + padding;


const Digit = ({ place, value }: { place: number; value: number }) => {
  let valueRoundedToPlace = Math.floor(value / place);
  let animatedValue = useSpring(valueRoundedToPlace);

  useEffect(() => {
    animatedValue.set(valueRoundedToPlace);
  }, [animatedValue, valueRoundedToPlace]);

  return (
    <div style={{ height }} className="relative w-[1ch] tabular-nums">
      {Array.from(Array(10)).map((_, i) => (
        <Number key={i} mv={animatedValue} number={i} />
      ))}
    </div>
  );
}

export default Digit;