import Digit from "./Digit";
// credits to buildui.com

const fontSize = 20;

const Counter = ({ value }: { value: number }) => {
  return (
    <div
      style={{ fontSize }}
      className="flex rounded leading-none h-9 md:h-auto overflow-hidden"
    >
      <Digit place={100} value={value} />
      <Digit place={10} value={value} />
      <Digit place={1} value={value} />
    </div>
  );
}

export default Counter;