import { SetterOrUpdater } from "recoil";
import { eventStateType } from "../../../store/eventState";
import { QuizStandbyType, QuizTypes } from "../types/quiz.types";
import { CurrentlyShowTypes } from "../../../pages/activities/quiz/activity/currentlyShow.store";
import { QuizAnswerTypes } from "../../../pages/activities/quiz/store";
import {
	answerStatusStoreDefault,
	AnswerStatusStoreType,
} from "../../../pages/activities/quiz/activity/answerStatus.store";

const quizHandler = (
	data: QuizTypes,
	setAnswerStatus: SetterOrUpdater<AnswerStatusStoreType>,
	setCurrentlyShowQuiz: SetterOrUpdater<CurrentlyShowTypes>,
	setUserAnswer: SetterOrUpdater<QuizAnswerTypes>,
	setState: SetterOrUpdater<eventStateType>
) => {
	const { action } = data.data.event_data;

	setAnswerStatus(answerStatusStoreDefault);
	setCurrentlyShowQuiz("question");

	switch (action) {
		case "content-standby":
			setState((prev) => ({
				...prev,
				activeContentId: data.data.content_id,
				activity: {
					activityName: data.data.content_type_name,
					activityType: data.data.content_type,
				},
				activityTitle: (data as QuizStandbyType).data.event_data.content_title,
				description: (data as QuizStandbyType).data.event_data
					.content_description,
			}));
			return "/event/activity/lobby";
		case "start":
			const showable_content = data.data.event_data.showable_content;
			const last_answer = data.data.last_answer;

			const currentIndex = showable_content.questions.findIndex(
				(item) => item.id === last_answer?.tf_question_id
			);

			setUserAnswer({
				currentIndex: currentIndex + 1,
				total: showable_content.questions.length,
				answers: showable_content.questions.map((q) => ({
					id: q.id.toString(),
					userSelect: 0,
				})),
				data: showable_content.questions.map((item) => ({
					description: item.description,
					id: item.id.toString(),
					results: item.results,
					text: item.text,
				})),
			});
			setState((prev) => ({
				...prev,
				activity: {
					activityName: data.data.content_type_name,
					activityType: data.data.content_type,
				},
				activeContentId: data.data.content_id,
				activityName: data.data.content_type_name,
				showHeaderControl: true,
			}));

			if (currentIndex >= showable_content.questions.length - 1) {
				return "/event/activity/2/quiz/show-result";
			} else {
				return "/event/activity/2/quiz/quiz-start";
			}
		case "show_results":
			const showable_content_show_results =
				data.data.event_data.showable_content;

			setState((prev) => ({
				...prev,
				activity: {
					activityName: data.data.content_type_name,
					activityType: data.data.content_type,
				},
				activeContentId: data.data.content_id,
				activityName: data.data.content_type_name,
				showHeaderControl: false,
			}));

			setUserAnswer((prev) => ({
				...prev,
				data: showable_content_show_results.questions.map((item) => ({
					description: item.description,
					text: item.text,
					results: item.results,
					id: item.id.toString(),
				})),
			}));

			return "/event/activity/2/quiz/results";
		default:
			setState((prev) => ({
				...prev,
				showHeaderControl: false,
				activity: undefined,
			}));
			return "/event/activity/lobby";
	}
};

export default quizHandler;
