import { Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import useReFetcher from "../hooks/reFetcher/useRefetcher";
import { useRecoilValue } from "recoil";
import tempData from "../store/tempData.store";
import Logo from "../icons/Logo";

const StartScreen = ({ isInternalLoad }: { isInternalLoad: boolean }) => {
	const { t } = useTranslation();
	const { handleRefetch, loading } = useReFetcher(true);
	const temp = useRecoilValue(tempData);

	return (
		<div className="flex flex-col gap-8 items-center w-full">
			<div className="flex flex-col gap-[30px] rounded-[20px] bg-theme-blue p-8 w-full flex-1 max-w-[420px]">
				<div className="text-white flex items-center flex-col gap-4 md:gap-[10px] w-full">
					<p className="text-2xl leading-7 md:text-[28px] md:leading-[34px]">
						{t("Welcome")}
					</p>
					<h2 className="text-[30px] leading-9 md:text-[42px] md:leading-[50px] font-bold">
						{temp?.data.event_data.action !== "back-to-home" &&
							temp?.data.event_data.content_title}
					</h2>
					<p className="text-base leading-5 text-white tracking-[0.01px]  text-center">
						{temp?.data.event_data.action !== "back-to-home" &&
							(temp?.data.event_data.action === "show_results" ||
							temp?.data.event_data.action === "show_viewer"
								? temp?.data.event_data.results_description
								: temp?.data.event_data.content_description)}
					</p>
				</div>
				<Button
					size={"md"}
					variant={"primary"}
					className="primary-btn orange-btn w-full flex-1 px-16 !py-[13px] md:!py-[18px] md:!p-5 leading-[21.6px] font-bold text-lg"
					isDisabled={loading || isInternalLoad}
					isLoading={loading || isInternalLoad}
					onClick={handleRefetch}
				>
					{/* {t("Join")} */}
					{temp?.data.event_data.action !== "back-to-home" &&
						(temp?.data.event_data.action === "show_results" ||
						temp?.data.event_data.action === "show_viewer"
							? temp?.data.event_data.btn_result_text
							: temp?.data.event_data.btn_text)}
				</Button>
			</div>
			<Logo />
		</div>
	);
};

export default StartScreen;
