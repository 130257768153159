import { IoIosArrowRoundForward, IoIosArrowRoundBack } from "react-icons/io";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getAvatars } from "../../../lib/services/users";
import Carousel from "nuka-carousel";
import { useQuery } from "@tanstack/react-query";
import Circle from "../../../shared/Circle";
import cStyles from "./style.module.css";

const AvatarSelect = ({
	setSelectedImage,
	selectedImage,
}: {
	setSelectedImage: Dispatch<SetStateAction<string>>;
	selectedImage: string;
}) => {
	const [index, setIndex] = useState(0);
	const [images, setImages] = useState([]);

	const { data, isLoading, isSuccess } = useQuery({
		queryKey: ["avatars"],
		queryFn: getAvatars,
	});

	function shuffle(array: string[]) {
		let currentIndex = array.length;

		// While there remain elements to shuffle...
		while (currentIndex !== 0) {
			// Pick a remaining element...
			let randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex--;

			// And swap it with the current element.
			[array[currentIndex], array[randomIndex]] = [
				array[randomIndex],
				array[currentIndex],
			];
		}
	}

	useEffect(() => {
		if (isSuccess) {
			const arr = data?.data.data;
			shuffle(arr);
			setIndex(0);
			setSelectedImage(arr[0]);
			// setUserData((prev) => ({ ...prev, imgUrl: arr[0] }));
			setImages(arr);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSuccess]);

	useEffect(() => {
		console.log(index);
		setSelectedImage(images[index]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [index]);

	return (
		<div className="max-w-[420px]">
			<Carousel
				slidesToShow={1}
				cellSpacing={5}
				className="py-3 !w-[140px]"
				wrapAround={true}
				renderBottomCenterControls={() => <></>}
				renderCenterRightControls={(c) => {
					return (
						<Circle
							onClick={() => {
								setIndex((prev) => {
									return (prev + 1) % images.length;
								});
								c.nextSlide();
							}}
							className="bg-white w-12 h-12 text-violet-600 text-5xl relative left-[70px]"
						>
							<IoIosArrowRoundForward />
						</Circle>
					);
				}}
				renderCenterLeftControls={(c) => {
					return (
						<Circle
							onClick={() => {
								setIndex((prev) => {
									const current = prev - 1;
									if (current < 0) {
										return images.length - 1;
									}
									return current;
								});
								c.previousSlide();
							}}
							className="bg-white w-12 h-12 text-violet-600 text-5xl relative right-[70px]"
						>
							<IoIosArrowRoundBack />
						</Circle>
					);
				}}
				swiping={false}
			>
				{isLoading
					? [...Array(3)].map((_: any, i: number) => (
							<Circle
								key={i}
								className="w-[140px] h-[140px] animate-pulse bg-slate-200"
							/>
					  ))
					: images.map((item: string) => (
							<img
								key={item}
								src={`${item}`}
								alt="choose your avatar"
								className={`w-[140px] h-[140px] ${
									item === selectedImage && cStyles.selectedShadow
								}`}
								onClick={() => setSelectedImage(item)}
							/>
					  ))}
			</Carousel>
		</div>
	);
};

export default AvatarSelect;
