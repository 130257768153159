import Chip from "../../shared/Chip";
import EventStatus from "./userControl/EventStatus";
import UserAvatar from "./userControl/UserAvatar";

const UserControls = () => {

	return (
		<Chip className="flex items-center gap-2 text-lg font-bold capitalize text-white">
			<UserAvatar />
			<EventStatus />			
		</Chip>
	);
};

export default UserControls;
