import Chip from "../../../../shared/Chip";

const Option = ({
	option,
	percentage,
	mostChose,
}: {
	option: string;
	percentage: number;
	mostChose: boolean;
}) => {
	return (
		<Chip
			className={`flex justify-between !p-4 rounded gap-8 border-2 border-violet-600 ${
				mostChose ? "bg-violet-600" : "bg-white"
			}`}
		>
			<div className="flex gap-4 items-center">
				<Chip
					className={`text-center font-bold text-2xl leading-6 px-[18px] py-[10px] border-[2px] w-[100px] border-violet-600 bg-white text-violet-600`}
				>
					{percentage}%
				</Chip>
				<h4 className={`text-base ${mostChose ? "text-white" : "text-dolphin-700"} leading-[21.6px] font-bold`}>
					{option}
				</h4>
			</div>
		</Chip>
	);
};

export default Option;
