import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const { definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

export const popup = definePartsStyle({
  dialogContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dialog: {
    padding: "20px",
    borderRadius: "5px",
    overflow: "hidden",
    outline: "none",
    border: "none",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "white",
    width: "420px",
    height: "420px",
  },
});

export const info = definePartsStyle({
  dialogContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dialog: {
    outline: "none",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});