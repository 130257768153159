import { SetterOrUpdater } from "recoil";
import { eventStateType } from "../../../store/eventState";
import {
	SurveyInProgress,
	SurveyStart,
	SurveyTypes,
} from "../types/survey.api.types";
import { surveyStoreType } from "../../../pages/activities/survey/store";
import { UserSubmissionTypes } from "../../../pages/activities/survey/activity/store";

const surveyHandler = (
	data: SurveyTypes,
	setState: SetterOrUpdater<eventStateType>,
	setSurvey: SetterOrUpdater<surveyStoreType>,
	setSubmissionData: SetterOrUpdater<UserSubmissionTypes>
) => {
	const { action } = data.data.event_data;

	let questions;

	switch (action) {
		case "content-standby":
			setState((prev) => ({
				...prev,
				activeContentId: data.data.content_id,
				activity: {
					activityName: data.data.content_type_name,
					activityType: data.data.content_type,
				},
				activityTitle: (data as SurveyStart).data.event_data.content_title,
				description: (data as SurveyStart).data.event_data.content_description,
			}));
			console.log("/event/activity/lobby");
			return "/event/activity/lobby";
		case "start":
			setState((prev) => ({
				...prev,
				activity: {
					activityName: data.data.content_type_name,
					activityType: data.data.content_type,
				},
				activeContentId: data.data.content_id,
				activityName: data.data.content_type_name,
				showHeaderControl: true,
			}));
			questions = data.data.event_data.showable_content.questions.map(
				(item) => ({
					description: item.description,
					id: item.id.toString(),
					question: item.text,
					type_name: (item.type === 1 ? "Single" : "Multiple") as
						| "Single"
						| "Multiple",
					options: item.options.map((op) => ({
						id: op.id.toString(),
						option: op.text,
						option_percentage: 0,
						option_progress: 0,
					})),
				})
			);

			const currentIndex =
				data.data.event_data.showable_content.questions.findIndex(
					(item) =>
						item.id === (data as SurveyInProgress).data.last_answer?.question_id
				);

			setSurvey({
				currentIndex: currentIndex < 0 ? 0 : currentIndex,
				total: questions.length,
				questions: questions,
			});

			const userSelection = (data as SurveyInProgress).data.answers || [];

			const userSelected = questions.map((q, i) => {
				return {
					id: q.id.toString(),
					selected: userSelection[i] ? userSelection[i].options : [],
				};
			});

			setSubmissionData({
				currentIndex: currentIndex < 0 ? 0 : currentIndex,
				total: questions.length,
				userSelected: userSelected,
			});

			if (currentIndex === questions.length) {
				return "/event/activity/3/survey/show-result";
			} else {
				return "/event/activity/3/survey/player";
			}
		case "end":
			questions = data.data.event_data.showable_content.questions.map(
				(item) => ({
					description: item.description,
					id: item.id.toString(),
					question: item.text,
					type_name: item.type_name,
					options: item.options.map((op) => ({
						id: op.id.toString(),
						option: op.text,
						option_percentage: Math.trunc(op.option_percentage),
						option_progress: Math.trunc(+op.option_progress),
						option_prof: {
							Dermatologists: op.option_prof.Dermatologists,
							Reumatologists: op.option_prof.Reumatologists,
						},
					})),
				})
			);
			setSurvey({
				currentIndex: 0,
				total: questions.length,
				questions: questions,
			});
			return "/event/activity/3/survey/show-result";
		case "show_results":
			setState((prev) => ({
				...prev,
				activity: {
					activityName: data.data.content_type_name,
					activityType: data.data.content_type,
				},
				activeContentId: data.data.content_id,
				activityName: data.data.content_type_name,
				showHeaderControl: true,
			}));
			questions = data.data.event_data.showable_content.questions.map(
				(item) => ({
					description: item.description,
					id: item.id.toString(),
					question: item.text,
					type_name: item.type_name,
					options: item.options.map((op) => ({
						id: op.id.toString(),
						option: op.text,
						option_percentage: Math.trunc(op.option_percentage),
						option_progress: Math.trunc(+op.option_progress),
					})),
				})
			);
			setSurvey({
				currentIndex: 0,
				total: questions.length,
				questions: questions,
			});
			return "/event/activity/3/survey/results";
		default:
			setState((prev) => ({
				...prev,
				showHeaderControl: false,
				activity: undefined,
			}));
			return "/event/activity/lobby";
	}
};

export default surveyHandler;
