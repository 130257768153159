import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const NotFound = () => {
	const { t } = useTranslation();

  return (
    <div className="flex h-dvh w-screen flex-col items-center justify-center gap-5">
      <img src="/assets/404.svg" alt="" className="max-h-[60vh] w-full" />
      <p>{t("Congrats you have found a secret place, unfortunately you have to go back")}</p>
      <Link to="/">{t("Home")}</Link>
    </div>
  );
};

export default NotFound;
