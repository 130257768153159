const Game = () => {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="*" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.5062 2.19099C17.7593 0.946185 16.4315 0.116302 14.9377 0.116302H4.97926C3.48547 0.116302 2.1577 0.946185 1.41078 2.19099C0.49793 3.85076 0 5.6765 0 7.58521C0 12.1495 1.82574 15.884 4.14937 15.884C5.39418 15.884 6.39004 14.7221 7.13691 12.3155C7.21988 11.9835 7.55184 11.7346 7.9668 11.7346H12.0332C12.3652 11.7346 12.6971 11.9835 12.8631 12.3155C13.61 14.7221 14.6058 15.884 15.8506 15.884C18.1743 15.884 20 12.1495 20 7.58521C19.917 5.6765 19.4191 3.85076 18.5062 2.19099ZM15.7676 14.2242C15.5187 14.2242 14.9377 13.5603 14.3568 11.8176C14.0249 10.7387 13.029 10.0748 11.9502 10.0748H7.9668C6.88797 10.0748 5.89211 10.8217 5.56016 11.8176C4.97926 13.5603 4.39832 14.2242 4.14937 14.2242C3.31949 14.2242 1.65977 11.6516 1.65977 7.58521C1.65977 6.00845 2.07469 4.43169 2.8216 3.02091C3.31953 2.27404 4.14941 1.77611 4.9793 1.77611H14.9378C15.7677 1.77611 16.5976 2.27404 17.0955 3.02091C17.8424 4.43169 18.2573 6.00845 18.2573 7.58521C18.2573 11.6516 16.5975 14.2242 15.7676 14.2242Z" />
      <path d="M7.50004 5.5H6.66671V4.66668C6.66671 4.16669 6.33339 3.83337 5.83339 3.83337C5.33339 3.83337 5.00007 4.16669 5.00007 4.66668V5.5H4.16675C3.66675 5.5 3.33343 5.83332 3.33343 6.33332C3.33343 6.83332 3.66675 7.16664 4.16675 7.16664H5.00003V8C5.00003 8.5 5.33335 8.83331 5.83335 8.83331C6.33335 8.83331 6.66668 8.5 6.66668 8V7.16668H7.50004C8.00004 7.16668 8.33336 6.83336 8.33336 6.33336C8.33336 5.83332 8.00004 5.5 7.50004 5.5Z" />
      <path d="M15.4167 6.3333C16.107 6.3333 16.6667 5.77366 16.6667 5.0833C16.6667 4.39295 16.107 3.8333 15.4167 3.8333C14.7263 3.8333 14.1667 4.39295 14.1667 5.0833C14.1667 5.77366 14.7263 6.3333 15.4167 6.3333Z" />
      <path d="M12.9167 8.83331C13.607 8.83331 14.1667 8.27367 14.1667 7.58331C14.1667 6.89295 13.607 6.3333 12.9167 6.3333C12.2263 6.3333 11.6667 6.89295 11.6667 7.58331C11.6667 8.27367 12.2263 8.83331 12.9167 8.83331Z" />
    </svg>
  );
};

export default Game;
