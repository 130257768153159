import { atom } from "recoil";

type buzzerStoreType = {
  questions: {
    id: string;
    question: string;
    description: string;
    options: {
      id: string;
      option: string;
    }[];
  }[],
  winner: {
    first_name: string,
    id: string,
    image: string,
    last_name: string,
  }[]
  currentIndex: number,
  userAnsweringId: number;
  userIsAnswering: boolean;
  answerIsTrue: boolean;
}

const defaultBuzzerStore = {
  questions: [],
  currentIndex: 0,
  userAnsweringId: -1,
  userIsAnswering: false,
  answerIsTrue: false,
  winner: [],
}

const buzzerStore = atom<buzzerStoreType>({
  key: "buzzerStore",
  default: defaultBuzzerStore,
});

export default buzzerStore;