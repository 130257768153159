import { useTranslation } from "react-i18next";
import surveyState from "../store";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";

const Controls = () => {
	const { t } = useTranslation();
	const [survey, setSurvey] = useRecoilState(surveyState);
	const navigate = useNavigate();

	const handleNavigate = (action: "next" | "prev") => {
		if (action === "next") {
			if (survey.currentIndex === survey.total - 1) {
				navigate("/event/activity/end");
			} else {
				setSurvey((prev) => ({
					...prev,
					currentIndex: prev.currentIndex + 1,
				}));
			}
		} else {
			if (survey.currentIndex !== 0) {
				setSurvey((prev) => ({
					...prev,
					currentIndex: prev.currentIndex - 1,
				}));
			}
		}
	};

	return (
		<div className="flex items-center gap-9 p-2">
			<button
				onClick={() => handleNavigate("prev")}
				className={`py-[11px] text-lg leading-[21.8px] text-center font-bold text-white bg-orange-600 hover:bg-orange-700 rounded flex-1 ${
					survey.currentIndex === 0 && "hidden"
				}`}
			>
				{t("Prev")}
			</button>
			<button
				onClick={() => handleNavigate("next")}
				className={`py-[11px] text-lg leading-[21.8px] text-center font-bold text-white ${
					survey.currentIndex === survey.total - 1
						? "bg-cyan-600 hover:bg-cyan-700"
						: "bg-violet-600 hover:bg-violet-700"
				} rounded flex-1`}
			>
				{survey.currentIndex === survey.total - 1 ? t("Close") : t("Next")}
			</button>
		</div>
	);
};

export default Controls;
