import { atom } from "recoil";

export type ScoreStoreTypes = {
	overall_progress: number;
	score: {
		option_id: number;
		text: string;
		score: { all: number; Dermatologists: number; Reumatologists: number };
		scale: { all: number; Dermatologists: number; Reumatologists: number };
	}[];
};

export const defaultScoreData: ScoreStoreTypes = {
	score: [],
	overall_progress: 0,
};

const listScore = atom({
	key: "listScore",
	default: defaultScoreData,
});

export default listScore;
