import { useRecoilValue } from "recoil";
import eventState, { activityIndicatorSelector } from "../../store/eventState";

const ActivityIndicator = () => {
	const { showHeaderControl } = useRecoilValue(eventState);
	const Indicator = useRecoilValue(activityIndicatorSelector);

	if (showHeaderControl && Indicator) {
		return <Indicator />;
	} else return <></>;
};

export default ActivityIndicator;
