import SurveyCounter from "../components/activitiesControls/SurveyCounter";
import BuzzerCounter from "../components/activitiesControls/BuzzerCounter";
import QuizCounter from "../components/activitiesControls/QuizCounter";
import Reaction from "../components/activitiesControls/Reaction";

export const mapExerciseToIndicator = (exerciseId: number) => {
  if (exerciseId === 1) {
    return Reaction;
  } else if (exerciseId === 3) {
    return SurveyCounter;
  } else if (exerciseId === 2) {
    return QuizCounter;
  } else if (exerciseId === 4) {
    return BuzzerCounter;
  }

  return undefined;
};
