// import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import usePresenceChannel from "../hooks/events/usePresence";
import { useEffect } from "react";
// import { useRecoilValue } from "recoil";
// import eventState from "../store/eventState";

const ContextFullLayout = () => {
	const navigate = useNavigate();
	// const event = useRecoilValue(eventState);

	usePresenceChannel();

	useEffect(() => {
		const data = localStorage.getItem("user_info");
		if (data) {
			const resolvedData = JSON.parse(data);
			if (
				!(
					resolvedData?.firstName &&
					resolvedData.firstName.length > 0 &&
					resolvedData?.lastName &&
					resolvedData.lastName.length > 0 &&
					resolvedData?.user_id &&
					resolvedData?.mobileNumber &&
					resolvedData.mobileNumber.length > 0 &&
					resolvedData?.imgUrl &&
					resolvedData.imgUrl.length > 0
				)
			) {
				navigate("/");
			}
		} else {
			navigate("/");
		}
	}, []);

	// useEffect(() => {
	// 	if (event.live) {
	// 		if (!window.location.href.includes("/event/activity")) {
	// 			navigate("/event/activity");
	// 		}
	// 	} else {
	// 		navigate("/event");
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [event.live]);

	return (
		<div className="h-dvh">
			<Outlet />
		</div>
	);
};

export default ContextFullLayout;
