import { useRecoilState } from "recoil";
import userStore from "../../../store/userStore";

const UserAvatar = () => {
	const [userInfo] = useRecoilState(userStore);

	return (
		<div className="md:group flex items-center gap-2 transition-all duration-300">
			<img src={`${userInfo.imgUrl}`} alt="user" className="h-9 md:h-12 w-9 md:w-12" />
			<p
				className="w-0 overflow-hidden text-dolphin-700 duration-300 group-hover:w-[140px] hidden md:block"
				style={{ whiteSpace: "nowrap" }}
			>
				{`${userInfo.firstName} ${userInfo.lastName}`}
			</p>
		</div>
	);
};

export default UserAvatar;
