import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  inputAnatomy.keys
);

const login = definePartsStyle({
  field: {
    fontFamily: "Fira Sans",
    fontSize: "18px",
    color: "black",
    p: "16px",
    textAlign: "center",
    // _placeholder: {
    //   color: "black",
    // },
    outline: "none",
    borderRadius: "4px",
  },
});

export const inputTheme = defineMultiStyleConfig({
  variants: {
    login,
  },
});
