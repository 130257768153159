import Clock from "../../../icons/Clock";
import { useTranslation } from "react-i18next";

const ShowResult = () => {
	const { t } = useTranslation();

	return (
		<div className="bg-denim-600 flex-1 p-1 mx-2 mb-2 md:mx-5 md:mb-5 rounded flex flex-col items-center justify-center gap-[50px]">
			<p className="fill-white">
				<Clock />
			</p>
			<div className="flex flex-col gap-[10px]">
				<div className="text-white max-w-[820px] text-[30px] text-center">
					<h3>{t("Well done Survey")}!</h3>
				</div>
				<p className="text-lg text-white leading-[21px] text-center">
					{t("The survey is about to be completed by some users. Wait for everyone to finish")}
				</p>
			</div>
		</div>
	);
};

export default ShowResult;
