import React from "react";

type Props = React.HTMLAttributes<HTMLDivElement>;

const Circle = ({ children, className, ...rest }: Props) => {
  return (
    <div className={`aspect-square rounded-full ${className}`} {...rest}>
      {children}
    </div>
  );
};

export default Circle;
