const Buzzer = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.2163 7.9559C19.3908 8.66922 18.7908 9.45408 17.7793 9.45408H2.22067C1.20919 9.45408 0.609178 8.66922 0.783703 7.9559C1.79538 3.821 5.53764 0.75 10 0.75C14.4624 0.75 18.2046 3.821 19.2163 7.9559ZM0.791428 15.5442C0.791428 14.6081 1.5533 13.8452 2.49806 13.8452H17.4882C18.4329 13.8452 19.1948 14.6081 19.1948 15.5442V17.551C19.1948 18.4871 18.4329 19.25 17.4882 19.25H10.1336H2.49806C1.55331 19.25 0.791428 18.4871 0.791428 17.551V15.5442Z"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default Buzzer;
