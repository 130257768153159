import { Outlet, createBrowserRouter } from "react-router-dom";
import Layout from "../layout/Layout";

// ! Survey Pages
import ShowResultSurvey from "../pages/activities/survey/ShowResult";
import Results from "../pages/activities/survey/result/Results";
import Survey from "../pages/activities/survey/activity/Survey";

// ! Quiz Pages
import QuizResults from "../pages/activities/quiz/QuizResults/QuizResults";
import QuizShowResults from "../pages/activities/quiz/QuizShowResults";
import Quiz from "../pages/activities/quiz/activity/Quiz";

// ! User Pages
import EventWaiting from "../pages/user/EventWaiting";
import FirstAccess from "../pages/user/FirstAccess";
import Welcome from "../pages/user/welcome/Welcome";
import Login from "../pages/user/welcome/Login";

// ! Utility Pages
import SurveyErrorCatcher from "../pages/activities/survey/SurveyErrorCatcher";
import ActivityStartScreen from "../pages/activities/ActivityStartScreen";
import QuizErrorCatcher from "../pages/activities/quiz/QuizErrorCatcher";
import ContextFullLayout from "../layout/ContextFullLayout";
import ErrorElement from "../components/ErrorElement";
import UnAuthorized from "../pages/403/UnAuthorized";
import ActivityEnd from "../pages/user/ActivityEnd";
import Redirect from "../pages/user/Redirect";
import Reminder from "../pages/user/Reminder";
import Lobby from "../pages/user/Lobby";
import NotFound from "../404";

const useRouter = () => {
	const routes = createBrowserRouter([
		{
			path: "/event",
			element: <ContextFullLayout />,
			errorElement: <ErrorElement />,
			children: [
				{
					// ! Join To Activity Button
					path: "",
					element: <ActivityStartScreen />,
				},
				{
					path: "activity",
					element: <Layout />,
					children: [
						{
							// ! Lobby
							path: "lobby",
							element: <Lobby />,
						},
						{
							path: ":id",
							element: <Outlet />,
							children: [
								{
									// ! This redirects to /event where "Join To Activity Button"
									path: "",
									element: <Redirect />,
								},
								//#region survey
								// ! Survey routes
								{
									path: "survey",
									element: <SurveyErrorCatcher />,
									children: [
										{
											path: "player",
											element: <Survey />,
										},
										{
											path: "show-result",
											element: <ShowResultSurvey />,
										},
										{
											path: "results",
											element: <Results />,
										},
									],
								},
								//#endregion survey

								//#region quiz
								// ! Quiz routes
								{
									path: "quiz",
									element: <QuizErrorCatcher />,
									children: [
										{
											path: "quiz-start",
											element: <Quiz />,
										},
										{
											path: "show-result",
											element: <QuizShowResults />,
										},
										{
											path: "results",
											element: <QuizResults />,
										},
									],
								},
								//#endregion quiz
							],
						},
						{
							path: "end",
							element: <ActivityEnd />,
						},
					],
				},
			],
		},
		// ! User Routes
		{
			path: "/thanks",
			element: <EventWaiting />,
		},
		{
			path: "/activity/reminder",
			element: <Reminder />,
		},
		{
			path: "/",
			element: <FirstAccess />,
		},
		{
			path: "/login",
			element: <Login />,
		},
		{
			path: "/welcome",
			element: <Welcome />,
		},
		{
			path: "*",
			element: <NotFound />,
		},
		{
			path: "/un-authorized-access",
			element: <UnAuthorized />,
		},
	]);

	return routes;
};

export default useRouter;
