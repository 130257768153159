import { MotionValue, useTransform, motion } from "framer-motion";

const height = 36;

const Number = ({ mv, number }: { mv: MotionValue; number: number }) => {
	let y = useTransform(mv, (latest) => {
		let placeValue = latest % 10;
		let offset = (10 + number - placeValue) % 10;

		let memo = offset * height;

		if (offset > 5) {
			memo -= 10 * height;
		}

		return memo;
	});

	return (
		<motion.span
			style={{ y }}
			className="absolute inset-0 flex items-center justify-center !text-base font-bold !h-[39px]"
		>
			{number}
		</motion.span>
	);
};

export default Number;
