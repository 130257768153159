/* eslint-disable react-hooks/exhaustive-deps */
// import { useState } from "react";
import { useRecoilState } from "recoil";
import eventState from "../../store/eventState";
import { useNavigate } from "react-router-dom";
import userAnswers from "../../pages/activities/quiz/store";
import Pusher from "pusher-js/types/src/core/pusher";

export type Actions =
	| "start"
	| "show"
	| "end"
	| "show_results"
	| "prev"
	| "next";

type normalMsg = {
	content_id: number;
	content_type: number;
	content_type_name: string;
	action: Actions;
	showable_content: {
		id: number;
		title: string;
		description: string;
		type: number;
		type_name: "True/False";
		overall_progress: string;
		questions: {
			id: number;
			text: string;
			description: string;
			results: {
				false: number;
				true: number;
				true_count: number;
				false_count: number;
				correct_answer: boolean;
			};
		}[];
	};
	question_id: null;
};

type QuizAnswer = {
	data: {
		id: number;
		title: string;
		description: string;
		type: 2;
		type_name: "True/False";
		overall_progress: number;
		questions: {
			id: number;
			text: string;
			description: string;
			results: {
				false: number;
				true: number;
				true_count: number;
				false_count: number;
				correct_answer: boolean;
			};
		}[];
	};
	eventType: "true/false";
	channel: null;
};

type MsgType = {
	data: normalMsg;
	eventType: string;
};

const useQuizChannel = () => {
	const navigate = useNavigate();

	const [, setUserAnswers] = useRecoilState(userAnswers);

	const [, setState] = useRecoilState(eventState);
	// const [count, setCount] = useState(1);
	const channel_name = "private-presenter";

	const handleStartEvent = (data: MsgType) => {
		const cData = data.data;
		setState((prev) => ({
			...prev,
			activity: {
				activityName: cData.content_type_name,
				activityType: cData.content_type,
			},
			activeContentId: cData.content_id,
			activityName: cData.content_type_name,
			activityTitle: cData.showable_content.title,
			showHeaderControl: true,
		}));
		setUserAnswers({
			currentIndex: 0,
			answers: cData.showable_content.questions.map((q) => ({
				id: q.id.toString(),
				userSelect: 0,
			})),
			data: cData.showable_content.questions.map((item) => ({
				description: item.description,
				text: item.text,
				results: item.results,
				id: item.id.toString(),
			})),
			total: cData.showable_content.questions.length,
		});
	};

	const handleKeepUp = (data: QuizAnswer) => {
		const cData = data.data;
		setState((prev) => ({
			...prev,
			activity: {
				activityName: cData.type_name,
				activityType: cData.type,
			},
			activeContentId: cData.id,
			activityName: cData.type_name,
			activityTitle: cData.title,
			description: cData.description,
			showHeaderControl: true,
		}));
		setUserAnswers({
			currentIndex: 0,
			answers: cData.questions.map((q) => ({
				id: q.id.toString(),
				userSelect: 0,
			})),
			data: cData.questions.map((item) => ({
				description: item.description,
				text: item.text,
				results: item.results,
				id: item.id.toString(),
			})),
			total: cData.questions.length,
		});
	};

	const handleQuizInit = (pusher: Pusher) => {
		const quizChannel = pusher.subscribe(channel_name);

		// console.log("this must appear once quiz channel", count);
		// setCount((prev) => prev + 1);

		quizChannel.bind("true/false", (data: MsgType | QuizAnswer) => {
			if ("action" in data.data) {
				switch (data.data.action) {
					case "start":
						handleStartEvent(data as MsgType);
						navigate(
							`/event/activity/${data.data.content_type}/quiz/quiz-start`
						);
						break;
					case "end":
						// set results here
						navigate(
							`/event/activity/${data.data.content_type}/quiz/show-result`
						);
						break;
					case "show_results":
						const cData = data.data;

						setUserAnswers((prev) => ({
							...prev,
							data: cData.showable_content.questions.map((item) => ({
								description: item.description,
								text: item.text,
								results: item.results,
								id: item.id.toString(),
							})),
						}));
						navigate(`/event/activity/${data.data.content_type}/quiz/results`);
						break;
					default:
						break;
				}
			} else if (
				!(
					window.location.href.includes("quiz-start") ||
					window.location.href.includes("show-result") ||
					window.location.href.includes("results")
				)
			) {
				handleKeepUp(data as QuizAnswer);
				navigate(`/event/activity/2/quiz/quiz-start`);
			}
		});

		// quizChannel.bind(
		// 	"content-end",
		// 	(_: { data: []; eventType: "content-end" }) => {
		// 		setState((prev) => ({
		// 			...prev,
		// 			activeContentId: -1,
		// 			activity: undefined,
		// 			showHeaderControl: false,
		// 			activityTitle: "",
		// 		}));
		// 		navigate("/event/activity");
		// 	}
		// );
	};

	return {
		handleQuizInit,
	};
};

export default useQuizChannel;
